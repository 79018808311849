import React, { useState, useEffect } from 'react';
import { BsFillPersonPlusFill } from 'react-icons/bs';

import { rfFichajes } from '../../../common/useRefresh';
import useData from '../../../common/useData';
import { useRol } from '../../../common/useRol';
import MultiSelectCCAA from '../../common/MultiSelectCCAA';
import { soCreateFichajes } from '../../../common/useOpen';
import { EnhancedTable } from '../components/Table';
import CreateFichaje from '../components/CreateFichaje';

import { ExportCSV } from '../../common/Export';
import dayjs from 'dayjs';
import BasicDateTimePicker from '../components/SelectDateRange';

export default function List() {
	const { setCreateFichaje } = soCreateFichajes();
	const [fichajes, setFichajes] = useState([]);

	const [data, setData] = useState({
		ccaa: '',
		sede: ''
	});

	const [filterDataInicio, setFilterDataInicio] = useState('');
	const [filterDataFin, setFilterDataFin] = useState(dayjs(new Date()));

	const { info } = useData();
	const { rol } = useRol();

	const { fichajesStatus } = rfFichajes();

	const [dniFilter, setDniFilter] = useState('');

	useEffect(() => {
		// if (data.ccaa !== '' || (filterDataInicio !== '' && filterDataFin !== '') || dniFilter !== '') {
		// let selCCAA = data.ccaa == "all" ? "" : data;
		fetch('https://back.ugt-sp.es/getAllFichajesCCAADev', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				data: data,
				filterDataInicio: filterDataInicio,
				filterDataFin: filterDataFin,
				dni: dniFilter,
				userDni: info.dni
			})
		})
			.then((results) => {
				return results.json();
			})
			.then((data) => {
				setFichajes(data.fichajes);
			})
			.catch((error) => {
				console.log(error);
			});
		// }
	}, [data, filterDataInicio, filterDataFin, dniFilter, fichajesStatus]);

	// useEffect(() => {
	//     fetch("https://back.ugt-sp.es/getAllFichajes",{
	//         method: 'POST',
	//         headers: {
	//             'Content-Type': 'application/json',
	//         },
	//         body: JSON.stringify({dni: info.dni}),
	//     }).then(results => {
	//         return results.json();
	//     })
	//     .then(data => {
	//         setFichajes(data.fichajes);
	//     })
	//     .catch((error) => {
	//         console.log(error);
	//     });
	// } ,[fichajesStatus])

	return (
		<div className='w-100 bg-grey scroll-y p-2 pt-3'>
			<div className='container-fluid fade-in'>
				<div className='row'>
					<div className='col-sm-6'>
						<h1>Fichajes</h1>
					</div>
					<div className='col-sm-6 d-flex justify-content-end pt-2 pb-2'>
						<button
							type='button'
							className='btn btn-primary me-2'
							onClick={() => {
								setCreateFichaje(true);
							}}
						>
							<BsFillPersonPlusFill /> Crear
						</button>
						{/* meter un condicional de loading, que mientras cargue que no haga nada el boton */}
						<ExportCSV
							csvData={fichajes.reverse()}
							fileName={'fichajes'}
							convertDate={true}
						/>
					</div>
					{/* <div className="col-sm-6">
                        <input type="search" className="form-control ps-2" placeholder="Buscar fichaje..." onChange={(e) => {
                            setSearchEmployees(e.target.value)
                        }}></input>
                    </div> */}
					{rol === 'Administrador' || rol === 'Responsable_ccaa' ? (
						<>
							<div className='col-sm-5'>
								{/* <SelectCCAA changeFunction={setSelectedCCAA} /> */}
								<div className='row'>
									<div className='col-6'>
										<MultiSelectCCAA
											data={data}
											dataType='filtro'
											changeFunction={setData}
										/>
									</div>

									<div className='col-6 p-3 d-flex'>
										<input
											type='text'
											className='form-control'
											defaultValue={dniFilter}
											style={{ backgroundColor: 'transparent' }}
											placeholder='DNI Empleado'
											onChange={(e) => setDniFilter(e.target.value)}
										/>
									</div>
								</div>
							</div>

							<div className='col-sm-7 text-end'>
								<BasicDateTimePicker
									type='inicio'
									changeFunction={setFilterDataInicio}
								/>
								<BasicDateTimePicker
									type='fin'
									changeFunction={setFilterDataFin}
								/>
							</div>
						</>
					) : (
						<div className='w-100 text-end d-flex justify-content-between'>
							<input
								type='text'
								className='form-control w-50'
								defaultValue={dniFilter}
								style={{ backgroundColor: 'transparent' }}
								placeholder='DNI Empleado'
								onChange={(e) => {
									if (e.target.value.length >= 3)
										setDniFilter(e.target.value);

									if (e.target.value.length === 0) setDniFilter('');
								}}
							/>

							<div>
								<BasicDateTimePicker
									type='inicio'
									changeFunction={setFilterDataInicio}
								/>
								<BasicDateTimePicker
									type='fin'
									changeFunction={setFilterDataFin}
								/>
							</div>
						</div>
					)}
				</div>

				<div className='appendEmployeesDiv mt-3'>
					{fichajes.length > 0 && fichajes ? (
						<EnhancedTable data={fichajes} />
					) : (
						<EnhancedTable data={[]} />
					)}
				</div>

				<CreateFichaje />
			</div>
		</div>
	);
}
