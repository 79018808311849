import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import 'dayjs/locale/es';

export default function BasicDateTimePicker({ type, changeFunction }) {
	const [value, setValue] = React.useState(type == 'fin' ? dayjs(new Date()) : '');

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='es'>
			<DateTimePicker
				renderInput={(props) => <TextField {...props} />}
				className='me-3'
				label={'Fecha de ' + type}
				value={value}
				onChange={(newValue) => {
					setValue(newValue);
					changeFunction(newValue);
				}}
			/>
		</LocalizationProvider>
	);
}
