import React, { useEffect, useState } from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar';

import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import useData from '../../common/useData';
import 'moment/locale/es'; // added import for Spanish locale

const icons = {
    today: "Hoy",
    previous: "Anterior",
    next: "Siguiente",
    month: "Mes",
    week: "Semana",
    day: "Día",
    agenda: "Agenda",
    date: "Fecha",
    time: "Hora",
    event: "Evento",
};

export const Calendario = () => {
    const {info} = useData();
    const [list, setList] = useState([])

    useEffect(() => {
        fetch("https://back.ugt-sp.es/getEventosDev", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                dni: info.dni
            })
        }).then(res => res.json()).then(data => {
            let list = [];

            data.eventos?.map(event => {
                list.push({
                    id: event.id,
                    title: event.title ? "Festivo" + (event.sede ? " " + event.sede : "") + ": " + event.title : event.type == 2 ? 'Jornada Reducida' : 'Evento',
                    start: moment(event.fechaInicio).toDate(),
                    end: moment(event.fechaFin).toDate(),
                    color: event.type == 1 ? 'red' : event.type == 2 ? 'green' : 'blue'
                })
            })

            setList(list)
        }).catch(res => {
            console.log('error')
        })
    }, [])

    return (
        <Calendar
            events={list}
            defaultDate={moment().toDate()}
            localizer={momentLocalizer(moment)}
            className="p-4"
            style={{ height: "calc(100% - 56px)" }}
            culture={"es-ES"}
            eventPropGetter={event => {
                const eventData = list.find(ot => ot.id === event.id);
                const backgroundColor = eventData && eventData.color;
                return { style: { backgroundColor } };
            }}
            messages={icons}
        />
    )
}
