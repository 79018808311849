import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

import { soCreateEmployee, soEmployee } from '../../../common/useOpen';
import { CalendarMonth, Schedule } from '@mui/icons-material';
import { ModelEventsEmployee } from './ModelEventsEmployee';
import { ModalHorario } from './ModalHorario';

function createData(
	dni,
	nombre,
	apellidos,
	ccaa,
	sede,
	direccion,
	cp,
	provincia,
	localidad,
	telefono,
	vacacionesAMayores,
	email,
	horario,
	vacaciones,
	libres,
	horasMensuales,
	totalHoras,
	totalHorasMensualesSede,
	totalHorasAnualesSede,
	horasMensualesSede,
	horasAnuales,
	activo
) {
	return {
		dni,
		nombre,
		apellidos,
		ccaa,
		sede,
		direccion,
		cp,
		provincia,
		localidad,
		telefono,
		vacacionesAMayores,
		email,
		horario,
		vacaciones,
		libres,
		horasMensuales,
		totalHoras,
		totalHorasMensualesSede,
		totalHorasAnualesSede,
		horasMensualesSede,
		horasAnuales,
		activo
	};
}

function descendingComparator(a, b, orderBy) {
	// Primero ordena por el campo activo de manera descendente
	if (b['activo'] < a['activo']) {
		return 1;
	}
	if (b['activo'] > a['activo']) {
		return -1;
	}

	// Si el campo activo es igual, entonces ordena por el campo nombre de manera ascendente
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}

	return 0;
}

function getComparator(order, orderBy) {
	// Ordena primero por el campo activo y luego por el campo nombre
	return order === 'desc'
		? (a, b) =>
				descendingComparator(a, b, orderBy) ||
				descendingComparator(a, b, 'nombre')
		: (a, b) =>
				-descendingComparator(a, b, orderBy) ||
				-descendingComparator(a, b, 'nombre');
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: 'dni',
		numeric: false,
		disablePadding: true,
		label: 'DNI'
	},
	{
		id: 'nombre',
		numeric: false,
		disablePadding: false,
		label: 'Nombre Completo'
	},
	//   {
	//     id: 'apellidos',
	//     numeric: false,
	//     disablePadding: false,
	//     label: 'Apellidos',
	//   },
	{
		id: 'ccaa',
		numeric: false,
		disablePadding: false,
		label: 'CCAA'
	},
	{
		id: 'sede',
		numeric: false,
		disablePadding: false,
		label: 'Sede'
	},
	{
		id: 'direccion',
		numeric: false,
		disablePadding: false,
		label: 'Dirección'
	},
	//   {
	//     id: 'cp',
	//     numeric: false,
	//     disablePadding: false,
	//     label: 'CP',
	//   },
	{
		id: 'provincia',
		numeric: false,
		disablePadding: false,
		label: 'Provincia'
	},
	// {
	// 	id: 'localidad',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Localidad'
	// },
	{
		id: 'email',
		numeric: false,
		disablePadding: false,
		label: 'Email'
	},
	{
		id: 'telefono',
		numeric: false,
		disablePadding: false,
		label: 'Teléfono'
	},
	{
		id: 'vacaciones',
		numeric: false,
		disablePadding: false,
		label: 'Vacaciones y Días libres'
	},
	//   {
	//     id: 'libres',
	//     numeric: false,
	//     disablePadding: false,
	//     label: 'Días libres',
	//   },
	{
		id: 'horasMensuales',
		numeric: false,
		disablePadding: false,
		label: 'Horas mensuales'
	},
	{
		id: 'horasAnuales',
		numeric: false,
		disablePadding: false,
		label: 'Horas Anuales'
	},
	{
		id: 'acciones',
		numeric: false,
		disablePadding: false,
		label: 'Acciones'
	}
];

function EnhancedTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
		props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<>
						{headCell.id !== 'acciones' ? (
							<TableCell
								key={headCell.id}
								align={headCell.numeric ? 'right' : 'left'}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<Box component='span' sx={visuallyHidden}>
											{order === 'desc'
												? 'sorted descending'
												: 'sorted ascending'}
										</Box>
									) : null}
								</TableSortLabel>
							</TableCell>
						) : (
							<TableCell
								key={headCell.id}
								align={headCell.numeric ? 'right' : 'left'}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								{headCell.label}
							</TableCell>
						)}
					</>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

function EnhancedTableToolbar(props) {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						)
				})
			}}
		>
			{
				<Typography
					sx={{ flex: '1 1 100%' }}
					variant='h6'
					id='tableTitle'
					component='div'
				>
					Empleados
				</Typography>
			}

			{
				<Tooltip title='Buscar empleado'>
					<IconButton>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			}
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired
};

export function EnhancedTable({ data }) {
	const { createEmployee, setCreateEmployee } = soCreateEmployee();
	const { setEmployee } = soEmployee();
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('nombre');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [rows, setRows] = React.useState(data);
	const [horasSede, setHorasSede] = React.useState([]);

	const [showEventos, setShowEventos] = React.useState(false);
	const [showHorarios, setShowHorarios] = React.useState(false);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function convertirSegundos(segundos) {
		let horas = Math.floor(segundos / 3600);
		segundos %= 3600;
		let minutos = Math.floor(segundos / 60);
		segundos %= 60;

		horas = horas < 10 ? '0' + horas : horas;
		minutos = minutos < 10 ? '0' + minutos : minutos;
		segundos = segundos < 10 ? '0' + segundos : segundos;
		return horas + ':' + minutos + ':' + segundos;
	}

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	React.useEffect(() => {
		setRows('');
		data.map((item) => {
			setRows((rows) => [
				...rows,
				createData(
					item.dni,
					item.nombre,
					item.apellidos,
					item.ccaa,
					item.sede,
					item.direccion,
					item.cp,
					item.provincia,
					item.localidad,
					item.telefono,
					item.vacacionesAMayores,
					item.email,
					item.horario,
					item.vacaciones,
					item.libres,
					item.horasMensuales,
					item.totalHoras,
					item.totalHorasMensualesSede,
					item.totalHorasAnualesSede,
					item.horasMensualesSede,
					item.horasAnuales,
					item.activo
				)
			]);
		});
	}, [data]);

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }} className='p-2'>
				<TableContainer>
					<Table
						sx={{ minWidth: 300 }}
						aria-labelledby='tableTitle'
						size={'small'}
						className='table-responsive'
					>
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
						/>
						<TableBody>
							{rows.length > 0 &&
								stableSort(rows, getComparator(order, orderBy))
									.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
									)
									.map((row, index) => {
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<TableRow
												hover
												tabIndex={-1}
												key={row.dni}
												className={
													row.activo == 0
														? 'disable-employee'
														: ''
												}
											>
												<TableCell align='left'>
													{row.dni}
												</TableCell>
												<TableCell
													align='left'
													style={{ minWidth: '190px' }}
												>
													{row.nombre} {row.apellidos}
												</TableCell>
												{/* <TableCell align="left">{row.apellidos}</TableCell> */}
												<TableCell align='left'>
													{row.ccaa}
												</TableCell>
												<TableCell align='left'>
													{row.sede}
												</TableCell>
												<TableCell align='left'>
													{row.direccion}, {row.cp}
												</TableCell>
												{/* <TableCell align="left">{row.cp}</TableCell> */}
												<TableCell align='left'>
													{row.provincia}
												</TableCell>
												{/* <TableCell align='left'>
													{row.localidad}
												</TableCell> */}
												<TableCell align='left'>
													{row.email}
												</TableCell>
												<TableCell align='left'>
													{row.telefono}
												</TableCell>
												<TableCell align='left'>
													Vacaciones: {row.vacaciones} <br />{' '}
													Permisos: {row.libres}
												</TableCell>
												{/* <TableCell align="left">{row.libres}</TableCell> */}
												<TableCell align='left'>
													{convertirSegundos(
														row.horasMensuales
													)}{' '}
													<br />
													de{' '}
													{row.totalHorasMensualesSede == -1
														? row.horasMensualesSede
														: row.totalHorasMensualesSede}{' '}
													horas
												</TableCell>
												<TableCell align='left'>
													{convertirSegundos(row.totalHoras)}{' '}
													<br />
													de{' '}
													{row.totalHorasAnualesSede == -1
														? row.horasAnuales
														: row.totalHorasAnualesSede}{' '}
													horas
												</TableCell>
												<TableCell align='center'>
													<div className='d-flex'>
														<button
															className='btn btn-secondary me-2'
															onClick={() => {
																setShowHorarios(true);
																setSelected(row);
															}}
														>
															<Schedule fontSize='small' />
														</button>
														<button
															className='btn btn-secondary me-2'
															onClick={() => {
																setShowEventos(true);
																setSelected(row);
															}}
														>
															<CalendarMonth fontSize='small' />
														</button>
														<button
															className='btn btn-primary'
															onClick={() => {
																setCreateEmployee(
																	true,
																	'edit'
																);
																setEmployee(row);
															}}
														>
															<Edit fontSize='small' />
														</button>
													</div>
												</TableCell>
											</TableRow>
										);
									})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 33 * emptyRows
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'Todos' }]}
					component='div'
					count={rows.length}
					rowsPerPage={rowsPerPage}
					labelRowsPerPage={'Filas por página'}
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
					}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>

			<ModelEventsEmployee
				open={showEventos}
				setOpen={setShowEventos}
				dni={selected.dni}
			/>

			<ModalHorario
				open={showHorarios}
				setOpen={setShowHorarios}
				userInfo={selected}
			/>
		</Box>
	);
}
