import React, { useEffect, useState } from 'react';
import { soCreateFichajes } from '../../../common/useOpen';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import SelectEmployee from './SelectEmployee';
import { rfFichajes } from '../../../common/useRefresh';
import { TextField } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 340,
  maxWidth:700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  maxHeight: '90%'
};

export default function CreateFichaje() {
    const { createFichaje, setCreateFichaje } = soCreateFichajes();
    const { fichajesStatus, setFichajesStatus } = rfFichajes();

    const [data, setData] = useState({
        dni: '',
        entrada: '',
        salida: '',
    });

    const [error, setError] = useState({
        dni: false,
        entrada: false,
        salida: false,
    });

    function createFichajes() {
        console.log(data);
        let error = false;
        const errorFounded = {
            dni: false,
            entrada: false,
            salida: false,
        };

        if (data.dni == '') {
            errorFounded.dni = true;
            error = true;
        }

        if (data.entrada == '') {
            errorFounded.entrada = true;
            error = true;
        }

        if (data.salida == '') {
            errorFounded.salida = true;
            error = true;
        }

        setError(errorFounded);

        if (!error) {
            fetch('https://back.ugt-sp.es/createFichaje', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(results => {
                return results.json();
            }
            ).then(res => {
                setCreateFichaje(false);
                setFichajesStatus(!fichajesStatus);
            }
            ).catch((error) => {
                console.log(error);
            });
        }
    }

    useEffect(() => {   
        setData({
            dni: '',
            entrada: new Date().getFullYear() + "-" + Number(new Date().getMonth() + 1) + "-" + new Date().getDate() + "T" + new Date().getHours() + ":" + new Date().getMinutes(),
            salida: new Date().getFullYear() + "-" + Number(new Date().getMonth() + 1) + "-" + new Date().getDate() + "T" + new Date().getHours() + ":" + new Date().getMinutes(),
        });
        setError({
            dni: false,
            entrada: false,
            salida: false,
        });
    }, [createFichaje])
    
    return (
        <>
            <Modal
                open={createFichaje}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">Crear Fichaje</Typography>
                    <CloseIcon sx={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }} onClick={() => setCreateFichaje(false)} />
                    
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Introduce a continuación los datos del fichaje:
                    </Typography>

                    <div className="form-group mt-3">
                        <SelectEmployee data={data} changeFunction={setData} dataType={"dni"} />
                    </div>
                    <div className="row mt-3 mb-3">
                        <div className="col-sm-6">
                            <label>Fecha/Hora Inicio</label>

                            <TextField
                                id="outlined-basic"
                                label="Entrada"
                                type={"datetime-local"}
                                className={"w-100"}
                                defaultValue={new Date().getFullYear() + "-" + Number(new Date().getMonth() + 1) + "-" + new Date().getDate() + "T" + new Date().getHours() + ":" + new Date().getMinutes()}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => setData({...data, entrada: e.target.value})}
                            />
                        </div>
                        <div className="col-sm-6">
                                <label>Fecha/Hora Fin</label>
                                <TextField
                                id="outlined-basic"
                                label="Salida"
                                type={"datetime-local"}
                                className={"w-100"}
                                defaultValue={new Date().getFullYear() + "-" + Number(new Date().getMonth() + 1) + "-" + new Date().getDate() + "T" + new Date().getHours() + ":" + new Date().getMinutes()}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => setData({...data, salida: e.target.value})}
                            />
                        </div>
                    </div>
                    

                  

                    <button type="button" className="btn btn-primary w-100 mt-1" onClick={() => createFichajes()}><CheckIcon/> Crear</button>
                </Box>
            </Modal>
        </>
    )
}
