import React, { useState, useEffect } from 'react';
import { BsSearch, BsFillPersonPlusFill } from 'react-icons/bs';

import Button from '@mui/material/Button';

import { EnhancedTable } from '../components/Table';
import CreateEmployee from './CreateEmployee';
import MultiSelectCCAA from '../../common/MultiSelectCCAA';

import { rfEmployees } from '../../../common/useRefresh';
import { soCreateEmployee } from '../../../common/useOpen';
import useData from '../../../common/useData';
import { useRol } from '../../../common/useRol';

import { ExportCSV } from '../../common/Export';
import { LeerCSV } from '../../../common/LeerCSV';
import { Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

export default function List() {
	const [employees, setEmployees] = useState([]);

	const { info } = useData();
	const { rol } = useRol();

	const [data, setData] = useState({
		ccaa: '',
		sede: ''
	});

	const [searchValEmployees, setSearchEmployees] = useState('');
	const [showModalCSV, setShowModalCSV] = useState(false);

	const { employeeStatus } = rfEmployees();
	const { setCreateEmployee } = soCreateEmployee();

	useEffect(() => {
		if (rol != 'Administrador' && rol != 'Responsable_ccaa') {
			return;
		}
		if (data.ccaa !== '') {
			fetch('https://back.ugt-sp.es/getAllEmployeesCCAA', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ data: data })
			})
				.then((results) => {
					return results.json();
				})
				.then((data) => {
					setEmployees(data.employees);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [data]);

	useEffect(() => {
		fetch('https://back.ugt-sp.es/getAllEmployeesDev', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ dni: info.dni })
		})
			.then((results) => {
				return results.json();
			})
			.then((data) => {
				setEmployees(data.employees);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [employeeStatus]);

	return (
		<div className='w-100 bg-grey scroll-y p-2 pt-3'>
			<Modal
				open={showModalCSV}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='scale-in bg-modal-incident flex-center'
			>
				<Box
					className='scale-in modal-close-incident'
					style={{ width: '1100px', overflow: 'auto', maxHeight: '500px' }}
				>
					<CloseIcon
						className='closeModal'
						onClick={() => setShowModalCSV(false)}
					/>
					<Typography id='modal-modal-title' variant='h6' component='h2'>
						Selecciona el archivo CSV{' '}
						<a href='/plantilla.xlsx' className='btn btn-action'>
							Descargar Plantilla <SimCardDownloadIcon />
						</a>
					</Typography>

					<LeerCSV setOpen={setShowModalCSV} />
				</Box>
			</Modal>

			<div className='container-fluid fade-in'>
				<div className='row'>
					<div className='col-sm-6'>
						<h1>Empleados</h1>
					</div>
					<div className='col-sm-6 d-flex justify-content-end pt-2 pb-2'>
						<button
							type='button'
							className='btn btn-dark me-2'
							onClick={() => {
								setShowModalCSV(true);
							}}
						>
							<BsFillPersonPlusFill /> Importar CSV
						</button>

						<button
							type='button'
							className='btn btn-primary me-2'
							onClick={() => {
								setCreateEmployee(true, null);
							}}
						>
							<BsFillPersonPlusFill /> Crear
						</button>
						{/* meter un condicional de loading, que mientras cargue que no haga nada el boton */}
						<ExportCSV csvData={employees} fileName={'empleados'} />
					</div>
					{/* <div className="col-12">
                        <input type="search" className="form-control ps-2" placeholder="Buscar empleado..." onChange={(e) => {
                            setSearchEmployees(e.target.value)
                        }}></input>
                    </div> */}
					{rol === 'Administrador' || rol === 'Responsable_ccaa' ? (
						<>
							<div className='col-sm-6'>
								<MultiSelectCCAA
									data={data}
									dataType='filtro'
									changeFunction={setData}
								/>
							</div>
						</>
					) : null}
				</div>

				<div className='appendEmployeesDiv mt-3'>
					<CreateEmployee />

					{employees.length > 0 && employees ? (
						<EnhancedTable data={employees} />
					) : (
						<EnhancedTable data={[]} />
					)}
				</div>
			</div>
		</div>
	);
}
