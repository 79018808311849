import React, { useEffect } from 'react';

import { NotificationManager } from 'react-notifications';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import useData from '../../../common/useData';
import { TableEvents } from './TableEvents';

export function ModelEventsEmployee({ open, setOpen, dni }) {
	const { info } = useData();

	const [eventos, setEventos] = React.useState([]);

	useEffect(() => {
		fetch('https://back.ugt-sp.es/getPersonalEventos', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				dni: dni
			})
		})
			.then((res) => res.json())
			.then((data) => {
				setEventos(data.eventos);
			})
			.catch((res) => {
				console.log('error');
			});
	}, [open]);

	return (
		<div className='w-100 bg-grey scroll-y'>
			<Modal
				open={open}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='scale-in bg-modal-incident d-flex align-items-center justify-content-center'
			>
				<Box
					className='scale-in modal-close-incident'
					sx={{ width: 800, maxHeight: '90vh' }}
				>
					<CloseIcon className='closeModal' onClick={() => setOpen(false)} />
					<Typography
						id='modal-modal-title'
						className='d-flex justify-content-between'
						variant='h6'
						component='h2'
					>
						<span>Solicitudes de vacaciones / días propios / otros</span>
					</Typography>

					<table className='table table-hover'>
						<thead>
							<tr>
								<th scope='col'>Motivo</th>
								<th scope='col'>Fecha de inicio</th>
								<th scope='col'>Fecha de fin</th>
								<th scope='col'>Año solicitud</th>
								<th scope='col'>Aprovado</th>
								<th scope='col'>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{eventos?.map((event, index) => {
								return (
									<tr key={index}>
										<td>
											{event.requestType == 'vacaciones'
												? 'Vacaciones'
												: event.requestType == 'diasPropios'
													? 'Días propios'
													: event.motivoOtros}
										</td>
										<td>
											{new Date(
												event.startDate
											).toLocaleDateString()}
										</td>
										<td>
											{new Date(event.endDate).toLocaleDateString()}
										</td>
										<td>
											{event.year}
										</td>
										<td>
											{event.aproved == 0
												? 'Pendiente'
												: event.aproved == 1
													? 'Aceptado'
													: 'Rechazado'}
										</td>
										<td>
											<button
												className='btn btn-danger'
												onClick={() => {
													fetch(
														'https://back.ugt-sp.es/deletePersonalEvento',
														{
															method: 'POST',
															headers: {
																'Content-Type':
																	'application/json'
															},
															body: JSON.stringify({
																id: event.id
															})
														}
													)
														.then((res) => res.json())
														.then((data) => {
															if (data.deleted) {
																setEventos(
																	eventos.filter(
																		(e) =>
																			e.id !=
																			event.id
																	)
																);
															}
														})
														.catch((res) => {
															console.log('error');
														});
												}}
											>
												Eliminar
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</Box>
			</Modal>
		</div>
	);
}
