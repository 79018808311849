import React from 'react'
import { Routes, Route, BrowserRouter as Router} from 'react-router-dom';

import Header from '../common/Header';

import Home from '../home/Home';
import Employees from '../employees/Employees';
import Fichajes from '../fichajes/Fichajes';
import Users from '../users/Users';
import Anuncios from '../anuncios/Anuncios';
import Informes from '../informes/Informes';

import { useRol } from '../../common/useRol';
import ChangePassword from '../login/ChangePassword';
import { Calendario } from '../calendar/Calendario';

export default function Main() {
    const {rol} = useRol();

    return (
        <div className="App scale-in">
            <Router>
                <Header />
                <Routes>
                    {/* <Route path="/" element={<Fichajes />} /> */}
                    <Route path="/fichajes/*" element={<Fichajes />} />
                    <Route path="/empleados/*" element={<Employees />} />
                    {rol === "Administrador" || rol === "Responsable_ccaa" ? <Route path="/usuarios" element={<Users />} /> : null }
                    <Route path="/anuncios" element={<Anuncios />} />
                    <Route path="/calendario" element={<Calendario />} />
                    <Route path="/informes" element={<Informes />} />
                </Routes>
            </Router>

            <ChangePassword type="firstLoggin" />
        </div>
    );
}
