import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

import { soModalRefused } from '../../../common/useOpen';

import { Check, Close } from '@mui/icons-material';
import { rfSoliciudes } from '../../../common/useRefresh';

import { ModalRefused } from '../../../common/ModalRefused';

function createData(
	id,
	dni,
	nombre,
	requestType,
	startDate,
	endDate,
	aproved,
	motivoOtros,
	year
) {
	return {
		id,
		dni,
		nombre,
		requestType,
		startDate,
		endDate,
		aproved,
		motivoOtros,
		year
	};
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: 'id',
		numeric: false,
		disablePadding: false,
		label: 'ID'
	},
	{
		id: 'dni',
		numeric: false,
		disablePadding: false,
		label: 'DNI'
	},
	{
		id: 'nombre',
		numeric: false,
		disablePadding: false,
		label: 'Nombre'
	},
	{
		id: 'requestType',
		numeric: false,
		disablePadding: false,
		label: 'Tipo de solicitud'
	},
	{
		id: 'startDate',
		numeric: false,
		disablePadding: false,
		label: 'Fecha de Inicio'
	},
	{
		id: 'endDate',
		numeric: false,
		disablePadding: false,
		label: 'Fecha de Fin'
	},
	{
		id: 'yearRequest',
		numeric: false,
		disablePadding: false,
		label: 'Año de solicitud'
	},
	{
		id: 'actions',
		numeric: false,
		disablePadding: false,
		label: 'Acciones'
	}
];

function EnhancedTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
		props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<>
						{headCell.id !== 'acciones' ? (
							<TableCell
								key={headCell.id}
								align={headCell.numeric ? 'right' : 'left'}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<Box component='span' sx={visuallyHidden}>
											{order === 'desc'
												? 'sorted descending'
												: 'sorted ascending'}
										</Box>
									) : null}
								</TableSortLabel>
							</TableCell>
						) : (
							<TableCell
								key={headCell.id}
								align={headCell.numeric ? 'right' : 'left'}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								{headCell.label}
							</TableCell>
						)}
					</>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	//   onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

function EnhancedTableToolbar(props) {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						)
				})
			}}
		>
			{
				<Typography
					sx={{ flex: '1 1 100%' }}
					variant='h6'
					id='tableTitle'
					component='div'
				>
					Solicitudes
				</Typography>
			}

			{
				<Tooltip title='Buscar solicitudes'>
					<IconButton>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			}
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired
};

export function TableSolicitudes({ data }) {
	const { solicitudesStatus, setSolicitudesStatus } = rfSoliciudes();
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('id');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const { setOpenRefused } = soModalRefused();
	const [rowID, setRowID] = useState(0);
	const [responseType, setResponseType] = useState(0);

	const [rows, setRows] = React.useState(data);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const responseRequest = (message, id, type) => {
		fetch('https://back.ugt-sp.es/responseRequest', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				id: id || rowID,
				response: type || responseType,
				message: message || ''
			})
		})
			.then((results) => {
				return results.json();
			})
			.then((data) => {
				setSolicitudesStatus(!solicitudesStatus);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	React.useEffect(() => {
		setRows('');
		data.map((item) => {
			setRows((rows) => [
				...rows,
				createData(
					item.id,
					item.dni,
					item.nombre,
					item.requestType,
					item.startDate,
					item.endDate,
					item.aproved,
					item.motivoOtros,
					item.year
				)
			]);
		});
	}, [data]);

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }} className='p-2'>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={'small'}
						className='table-responsive'
					>
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
						/>
						<TableBody>
							{rows.length > 0 &&
								stableSort(rows, getComparator(order, orderBy))
									.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
									)
									.map((row, index) => {
										//   const isItemSelected = isSelected(row.name);
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<TableRow hover tabIndex={-1} key={row.id}>
												<TableCell align='left'>
													{row.id}
												</TableCell>
												<TableCell align='left'>
													{row.dni}
												</TableCell>
												<TableCell align='left'>
													{row.nombre}
												</TableCell>
												<TableCell align='left'>
													{row.requestType == 'vacaciones'
														? 'Vacaciones'
														: row.requestType == 'diasPropios'
														? 'Días propios'
														: row.motivoOtros}
												</TableCell>
												<TableCell align='left'>
													{new Date(
														row.startDate
													).toLocaleDateString()}
												</TableCell>
												<TableCell align='left'>
													{new Date(
														row.endDate
													).toLocaleDateString()}
												</TableCell>
												<TableCell align='left'>
													{row.year}
												</TableCell>
												<TableCell align='left'>
													<Tooltip title='Rechazar solicitud'>
														<button
															className='btn btn-primary me-3'
															onClick={() => {
																setResponseType(2);
																setRowID(row.id);
																setOpenRefused(true);
															}}
														>
															<Close fontSize='small' />
														</button>
													</Tooltip>

													<Tooltip title='Aceptar solicitud'>
														<button
															className='btn btn-primary'
															onClick={() => {
																setResponseType(1);
																setRowID(row.id);
																setTimeout(() => {
																	responseRequest(
																		'No hay mensaje',
																		row.id,
																		1
																	);
																}, 300);
															}}
														>
															<Check fontSize='small' />
														</button>
													</Tooltip>
												</TableCell>
											</TableRow>
										);
									})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 33 * emptyRows
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'Todos' }]}
					component='div'
					count={rows.length}
					rowsPerPage={rowsPerPage}
					labelRowsPerPage={'Filas por página'}
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
					}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>

			<ModalRefused refusedFunction={responseRequest} />
		</Box>
	);
}
