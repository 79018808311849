import React from 'react'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { soConfirmDelete } from './useOpen';

export function ConfirmDelete({deleteFunction, message}) {
    const {openDelete, setOpenDelete} = soConfirmDelete();

    return (
        <div className="d-flex justify-content-center align-center w-100 bg-grey scroll-y">
            <Modal
                open={openDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="scale-in bg-modal-incident">
                    <Box className="scale-in modal-close-incident">
                        <CloseIcon className="closeModal" onClick={() => setOpenDelete(false)} />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {message ? message : "¿Está seguro de que desea deshabilitar al empleado?"}
                        </Typography>


                    <button className="btn btn-danger w-100 mt-2" onClick={() => {
                        deleteFunction();
                        setOpenDelete(false);
                    }}>Si</button>
                </Box>
            </Modal>
        </div>
    )
}
