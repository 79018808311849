import React, {useState, useEffect} from 'react'
import { BsFillPersonPlusFill } from 'react-icons/bs';
import CreateAnuncio from './components/CreateAnuncio';
import { EnhancedTable } from './components/Table';
import { soCreateAnuncio } from '../../common/useOpen';
import { rfAnuncios } from '../../common/useRefresh';

import useData from '../../common/useData';


export default function Anuncios() {
    const {setCreateAnuncio} = soCreateAnuncio();
    const {anunciosStatus} = rfAnuncios();
    const [anuncios, setAnuncios] = useState([]);

    const {info} = useData();

    useEffect(() => {
        fetch("https://back.ugt-sp.es/getAllAnuncios",{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({dni: info.dni}),
        }).then(results => {
            return results.json();
        })
        .then(data => {
            setAnuncios(data.anuncios);
        })
        .catch((error) => {
            console.log(error);
        });
    }, [anunciosStatus])

    return (
        <div className="w-100 bg-grey scroll-y p-2 pt-3">
            <div className="container-fluid fade-in">
                <div className="row">
                    <div className="col-sm-6">
                        <h1>Anuncios</h1>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-end pt-2 pb-2">
                        <button type="button" className="btn btn-primary" onClick={() => {
                            setCreateAnuncio(true, null)
                        }}><BsFillPersonPlusFill /> Crear</button>
                    </div>
                    {/* <div className="col-12">
                        <input type="search" className="form-control ps-2" placeholder="Buscar anuncio..." onChange={(e) => {
                            // setSearchEmployees(e.target.value)
                        }}></input>
                    </div> */}
                </div>
            
                <div className='appendEmployeesDiv mt-3'>
                    <CreateAnuncio />
                    
                    {anuncios.length > 0 && anuncios ? 
                        <EnhancedTable data={anuncios} />
                    :
                        <EnhancedTable data={[]} />
                    }
                </div>
            </div>
        </div>
    )
}