import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { RemoveRedEye } from '@mui/icons-material';
import { ModanInfo } from './ModanInfo';
import { soInfoFichajes } from '../../../common/useOpen';

function createData(
	id,
	dni,
	nombre,
	apellidos,
	ccaa,
	sede,
	entrada,
	salida,
	horas,
	late,
	observaciones_entrada,
	observaciones_salida,
	descansos,
	especial
) {
	return {
		id,
		dni,
		nombre,
		apellidos,
		ccaa,
		sede,
		entrada,
		salida,
		horas,
		late,
		observaciones_entrada,
		observaciones_salida,
		descansos,
		especial
	};
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: 'dni',
		numeric: false,
		disablePadding: true,
		label: 'DNI'
	},
	{
		id: 'nombre',
		numeric: false,
		disablePadding: false,
		label: 'Nombre'
	},
	{
		id: 'apellidos',
		numeric: false,
		disablePadding: false,
		label: 'Apellidos'
	},
	{
		id: 'ccaa',
		numeric: false,
		disablePadding: false,
		label: 'CCAA'
	},
	{
		id: 'sede',
		numeric: false,
		disablePadding: false,
		label: 'Sede'
	},
	{
		id: 'entrada',
		numeric: false,
		disablePadding: false,
		label: 'Entrada'
	},
	{
		id: 'salida',
		numeric: false,
		disablePadding: false,
		label: 'Salida'
	},
	{
		id: 'horas',
		numeric: false,
		disablePadding: false,
		label: 'Horas'
	},
	{
		id: 'late',
		numeric: false,
		disablePadding: false,
		label: 'Tarde'
	},
	{
		id: 'acciones',
		numeric: false,
		disablePadding: false,
		label: 'Acciones'
	}
];

function EnhancedTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
		props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<>
						{headCell.id !== 'acciones' ? (
							<TableCell
								key={headCell.id}
								align={headCell.numeric ? 'right' : 'left'}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<Box component='span' sx={visuallyHidden}>
											{order === 'desc'
												? 'sorted descending'
												: 'sorted ascending'}
										</Box>
									) : null}
								</TableSortLabel>
							</TableCell>
						) : (
							<TableCell
								key={headCell.id}
								align={headCell.numeric ? 'right' : 'left'}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								{headCell.label}
							</TableCell>
						)}
					</>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

function EnhancedTableToolbar(props) {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						)
				})
			}}
		>
			{
				<Typography
					sx={{ flex: '1 1 100%' }}
					variant='h6'
					id='tableTitle'
					component='div'
				>
					Fichajes
				</Typography>
			}

			{
				<Tooltip title='Buscar fichajes'>
					<IconButton>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			}
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired
};

export function EnhancedTable({ data }) {
	const { setOpenInfoModal } = soInfoFichajes();
	const [order, setOrder] = React.useState('desc');
	const [orderBy, setOrderBy] = React.useState('entrada');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [selectedUser, setSelectedUser] = React.useState({});
	const [rows, setRows] = React.useState(data);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	React.useEffect(() => {
		setRows('');

		setTimeout(() => {
			let rows = [];

			data.map((item) => {
				rows.push(
					createData(
						item.id,
						item.dni,
						item.nombre,
						item.apellidos,
						item.ccaa,
						item.sede,
						item.entrada,
						item.salida,
						item.horas,
						item.late,
						item.observaciones_entrada || 'Sin Comentarios',
						item.observaciones_salida || 'Sin Comentarios',
						item.descansos,
						item.especial
					)
				);
			});

			setRows(rows);
		}, 1000);
	}, [data]);

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }} className='p-2'>
				<TableContainer>
					<Table
						sx={{ minWidth: 300 }}
						aria-labelledby='tableTitle'
						size={'small'}
						className='table-responsive'
					>
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
						/>
						<TableBody>
							{rows.length > 0 &&
								stableSort(rows, getComparator(order, orderBy))
									.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
									)
									.map((row, index) => {
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<TableRow hover tabIndex={-1} key={index}>
												<TableCell align='left'>
													{row.dni}
												</TableCell>
												<TableCell align='left'>
													{row.nombre}
												</TableCell>
												<TableCell align='left'>
													{row.apellidos}
												</TableCell>
												<TableCell align='left'>
													{row.ccaa}
												</TableCell>
												<TableCell align='left'>
													{row.sede}
												</TableCell>
												<TableCell align='left'>
													{new Date(
														row.entrada
													).toLocaleDateString()}{' '}
													-{' '}
													{new Date(
														row.entrada
													).toLocaleTimeString()}
												</TableCell>
												{row.salida ? (
													<TableCell align='left'>
														{new Date(
															row.salida
														).toLocaleDateString()}{' '}
														-{' '}
														{new Date(
															row.salida
														).toLocaleTimeString()}
													</TableCell>
												) : (
													<TableCell align='left'>
														En curso
													</TableCell>
												)}
												<TableCell align='left'>
													{row.horas}
												</TableCell>
												<TableCell align='left'>
													{row.late == 0 ? 'En hora' : 'Tarde'}
												</TableCell>
												<TableCell align='center'>
													<button
														className='btn btn-primary'
														onClick={() => {
															setSelectedUser(row);
															setOpenInfoModal(true);
														}}
													>
														<RemoveRedEye />
													</button>
												</TableCell>
											</TableRow>
										);
									})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 33 * emptyRows
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'Todos' }]}
					component='div'
					count={rows.length}
					rowsPerPage={rowsPerPage}
					labelRowsPerPage={'Filas por página'}
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
					}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>

			<ModanInfo user={selectedUser} />
		</Box>
	);
}
