import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import useData from '../../common/useData';

export default function Tags({data, defaultValue, dataType, changeFunction, all}) {
    const [ccaa, setCCAA] = React.useState([]);
    const [sede, setSede] = React.useState([]);

    const [selectedCcaa, setSelectedCcaa] = React.useState([]);
    const [optionSedes, setOptionSedes] = React.useState([]);
    const [selectedSede, setSelectedSede] = React.useState([]);

    const [refresh, setRefresh] = React.useState(false);

    const {info} = useData();

    React.useEffect(() => {
        let ccaas = []
        let sedes = []
        fetch("https://back.ugt-sp.es/getAllCCAA",{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({dni: info.dni}),
        }).then(results => {
            return results.json();
        })
        .then(res => {
            if(dataType === "filtro" || all) {
                ccaas.push({label: 'Todas', value: 'all'});
            }
            res.ccaa.map((ccaa, index) => {
                ccaas.push({label: ccaa.nombre, value: ccaa.nombre});
            })

            setCCAA(ccaas);
        })
        .catch((error) => {
            console.log(error);
        });

        fetch("https://back.ugt-sp.es/getAllSedesDev",{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({dni: info.dni}),
        }).then(results => {
            return results.json();
        })
        .then(res => {
            if(dataType === "filtro" || all) {
                sedes.push({label: 'Todas', value: 'all'});
            }
            res.sedes.map((sede, index) => {
                sedes.push({label: sede.nombre, value: sede.nombre, ccaa: sede.ccaa});
            })

            setSede(sedes);
        })
        .catch((error) => {
            console.log(error);
        });
    }, [refresh])

    React.useEffect(() => {
        let options = [];

        if (dataType == "filtro") {
            if (selectedCcaa.length === 0) {
                options = sede;
                if (selectedCcaa.length === 0 && ccaa.length > 0) {
                    setRefresh(!refresh);
                }
            } else {
                if (selectedCcaa[0].value === "all") {
                    setCCAA(selectedCcaa);
                }
                options.push({label: "Todas", value: "all", ccaa: selectedCcaa[0].value});
            }
        }

        sede.map((sede, index) => {
            if (selectedCcaa.length > 0) {
                selectedCcaa.map((ccaa, index) => {
                    if (sede.ccaa == ccaa.value) {
                        options.push(sede);
                    }
                })
            }
        })

        setOptionSedes(options);
    }, [selectedCcaa])

    return (
        <div className="row">
            <div className="col-12">
                <Autocomplete
                    multiple
                    id="tags-standard"
                    options={ccaa}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" label="Comunidades Autónomas" placeholder="Comunidades Autónomas" />
                    )}
                    onChange={(event, newValue) => {
                        setSelectedCcaa(newValue);
                        data != "none" && (dataType == "ccaa" || dataType == "filtro") ? changeFunction({...data, ccaa: newValue}) : changeFunction(newValue);
                    }}
                    disabled={selectedSede.length > 0}
                />
            </div>
            <div className="col-12">
                {dataType == "filtro" && selectedCcaa.length > 0 && selectedCcaa[0].value != "all" && (
                    <Autocomplete
                        multiple
                        id="selected-sedes"
                        options={optionSedes}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <TextField {...params} variant="standard" label="Sedes" placeholder="Sedes" />
                        )}
                        onChange={(event, newValue) => {
                            setSelectedSede(newValue)
                            data != "none" && (dataType == "ccaa" || dataType == "filtro") ? changeFunction({...data, sede: newValue}) : changeFunction(newValue);
                        }}
                    />
                )}
            </div>



        </div>


    );
}