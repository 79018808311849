import React, { useState } from 'react'

import { useRol } from '../../common/useRol'
import useToken from '../../common/useToken';
import useData from '../../common/useData';
import 'animate.css';
import '../../styles/Login.css';
import CircularProgress from '@mui/material/CircularProgress';
import { sha256 } from 'js-sha256';
import { statusPassword } from './showChangeModal';

export default function Login() {
    const { setRol } = useRol()

    const { token, setToken } = useToken();
    const { openStatus, setOpenStatus } = statusPassword();

    const { setInfo } = useData();

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)

    const handleSubmit = async e => {
        e.preventDefault()

        try {
            setSending(true);
            setError('')
            const response = await fetch('https://back.ugt-sp.es/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({username, password})
            })
            const data = await response.json()

            if(data.error) {
                setError(data.error)
                setSending(false)
                setLoading(false)
            } else {
                setInfo({dni: username})
                setLoading(true)
                setTimeout(() => {
                    setToken(data.token)
                    setRol(data.rol)

                    window.localStorage.setItem('token', JSON.stringify(data.token))
                    window.localStorage.setItem('rol', data.rol)
                    window.localStorage.setItem('info', JSON.stringify({dni: username}))
                    window.localStorage.setItem('url', "/fichajes/lista")
                    window.history.pushState({}, null, "/fichajes/lista")
                }, 800)

                if(data.loggedIn == 0) {
                    setOpenStatus(true)
                }
            }
        } catch (error) {
            setError(error.message)
            setSending(false)
            setLoading(false)
        }
    }

    const handleKeypress = e => {
        if (e.key === "Enter") {
            handleSubmit();
        }
    };

    return (
        <div className="login-container">
            <img alt="logo" className={loading ? "mb-5 login-logo animate__animated animate__fadeOutUp" : "mb-5 login-logo animate__animated animate__fadeInUp"} style={{maxWidth:"300px"}} src="/img/logo1.png"></img>
            <div className={loading ? "login-form animate__animated animate__fadeOutUp" : "login-form animate__animated animate__fadeInUp animate__delay-0-3s"}>
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group-login">
                        <label htmlFor="username">Usuario</label>
                        <input type="text" className="form-control" id="username" placeholder="Introduce el usuario" value={username} onChange={e => setUsername(e.target.value)} />
                    </div>
                    <div className="form-group-login mt-4">
                        <label htmlFor="password">Contraseña</label>
                        <input type="password" className="form-control" id="password" placeholder="Introduce la contraseña" onChange={e => setPassword(sha256(e.target.value))} onKeyPress={handleKeypress} />
                    </div>
                    <button type="submit" className="btn btn-primary mt-4 w-100" disabled={sending}>{sending ?  <CircularProgress size={20} sx={{color: "white"}}/> : 'Acceder'}</button>
                </form>
            </div>
        </div>            
    )
}