import React, { useState, useEffect } from "react";
import { Link, Routes, Route} from 'react-router-dom';

import '../../styles/Employees.css';

import GroupIcon from '@mui/icons-material/Group';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import List from "./sections/List";

export default function Fichajes(){
    const clearFichajesNav = () => {
        setFichajes(true);
    }

    const [hideLMenu, setHideLMenu] = useState(true);
    const [fichajes, setFichajes] = useState(true);
        
    return (
  
        <div className="layout-lateral-menu fade-in">
            <div className="container-nav-menu">
                <div className={hideLMenu ? "nav-menu hide" : "nav-menu"}>
                    <Link to="lista"><div className={fichajes ? "nav-menu-button selected" : "nav-menu-button"} onClick={() => {clearFichajesNav(); setFichajes(true)}}>
                        <span className="icon"><GroupIcon fontSize="medium" /></span> Fichajes
                    </div> </Link>

                    <div className="expand-content" onClick={() => {setHideLMenu(!hideLMenu)}}>
                        {hideLMenu ? <KeyboardArrowRightIcon/> : <KeyboardArrowLeftIcon/>}
                    </div>
                </div>
             
            </div>

            <Routes>
                <Route path="lista" element={<List />} />
            </Routes>
        </div>

    );
}
