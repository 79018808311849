import React, { useState, useEffect } from "react";

export const TableEvents = ({events}) => {
    const [eventos, setEventos] = useState([])

    useEffect(() => {
        setEventos(events)
    }, [events])

    return (
        <div className="w-100 bg-grey scroll-y" style={{height: "90vh"}}>
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Tipo</th>
                            <th scope="col">Título</th>
                            <th scope="col">Fecha de inicio</th>
                            <th scope="col">Fecha de fin</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {eventos?.map(event => {
                            return (
                                <tr>
                                    <td>{event.type == 1 ? "Festivo": event.type == 2 ? "Jornada Reducida" : "Evento"}</td>
                                    <td>{event.title}</td>
                                    <td>{new Date(event.fechaInicio).toLocaleDateString()}</td>
                                    <td>{new Date(event.fechaFin).toLocaleDateString()}</td>
                                    <td>
                                        <button className="btn btn-danger" onClick={() => {
                                            fetch("https://back.ugt-sp.es/deleteEvento", {
                                                method: "POST",
                                                headers: {
                                                    "Content-Type": "application/json"
                                                },
                                                body: JSON.stringify({
                                                    id: event.id
                                                })
                                            }).then(res => res.json()).then(data => {
                                                if(data.deleted) {
                                                    setEventos(eventos.filter(e => e.id != event.id))
                                                }
                                            }).catch(res => {
                                                console.log('error')
                                            })
                                        }}>Eliminar</button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}