import { Autocomplete, TextField } from '@mui/material';
import React, {useState, useEffect} from 'react'

import useData from '../../common/useData'

export default function SelectSede({data, defaultValue, dataType, changeFunction, ccaa}) {
    const [sedes, setSedes] = useState([]);
    const [options, setOptions] = useState([]);

    const {info} = useData();

    useEffect(() => {
        fetch("https://back.ugt-sp.es/getAllSedesDev",{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({dni: info.dni}),
        }).then(results => {
            return results.json();
        })
        .then(data => {
            setSedes(data.sedes);
        })
        .catch((error) => {
            console.log(error);
        });
    }, [])

    useEffect(() => {
        let options = [];

        sedes.map((sede, index) => {
            if (data.ccaa && ccaa != "todos") {
                if (sede.ccaa == data.ccaa) {
                    options.push({label: sede.nombre, value: sede.nombre});
                }
            } else {
                options.push({label: sede.nombre, value: sede.nombre});
            }
        })

        setOptions(options);
    }, [data])

    return (
        <>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={options}
                value={data.sede}
                className="pt-2 w-100"
                renderInput={(params) => <TextField {...params} label="Sede" />}
                onChange={(e, newValue) => data != "none" ? dataType == 'sede' ? changeFunction({...data, sede: newValue.value}) : changeFunction(newValue.value) : changeFunction(newValue.value)}
            />
        </>
    )
}
