import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

import { rfAnuncios } from '../../../common/useRefresh';
import useData from '../../../common/useData';

import {soCreateAnuncio, soAnuncio, soConfirmDelete} from '../../../common/useOpen';
import { Delete } from '@mui/icons-material';
import Tags from '../../common/MultiSelectCCAA';
import SelectSede from '../../common/SelectSede';
import { ConfirmDelete } from '../../../common/ModalConfirmDelete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 340,
  maxWidth:700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  maxHeight: '90%'
};

export default function CreateAnuncio() {
    const {anunciosStatus, setAnunciosStatus} = rfAnuncios();
    const {createAnuncio, typeCreateAnuncio, setCreateAnuncio} = soCreateAnuncio();
    const {setOpenDelete} = soConfirmDelete();

    const {anuncio} = soAnuncio();

    const {info} = useData();

    const [data, setData] = useState({
        title: '',
        description: '',
        // sede: '',
        ccaa: ''
    });

    const [error, setError] = useState({
        title: false,
        description: false,
        // sede: false,
        ccaa: false
    });

    function createAnuncioBBDD() {
        let error = false;
        const errorFounded = {
            title: false,
            description: false,
            // sede: false,
            ccaa: false
        };

        if (data.title == '') {
            errorFounded.title = true;
            error = true;
        } 
        if (data.description == '') {
            errorFounded.description = true;
            error = true;
        }
        // if (data.sede == '') {
        //     errorFounded.sede = true;
        //     error = true;
        // }
        if (data.ccaa == '') {
            errorFounded.ccaa = true;
            error = true;
        }
        
        setError(errorFounded);

        if (!error) {
            data.author = info.dni;

            fetch('https://back.ugt-sp.es/createAnuncio', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(results => {
                return results.json();
            }
            ).then(res => {
                if (res.created) {
                    setAnunciosStatus(!anunciosStatus);
                    setCreateAnuncio(false);
                }
            }
            ).catch((error) => {
                console.log(error);
            });
        }
    }

    function editAnuncio(){
        let error = false;
        const errorFounded = {
            title: false,
            description: false,
            // sede: false,
            ccaa: false
        };

        if (data.title == '') {
            errorFounded.title = true;
            error = true;
        } 
        if (data.description == '') {
            errorFounded.description = true;
            error = true;
        }
        // if (data.sede == '') {
        //     errorFounded.sede = true;
        //     error = true;
        // }
        if (data.ccaa == '') {
            errorFounded.ccaa = true;
            error = true;
        }

        setError(errorFounded);

        if (!error) {
            data.author = info.dni;
            
            fetch('https://back.ugt-sp.es/updateAnuncio', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(results => {
                return results.json();
            }
            ).then(res => {
                if (res.updated) {
                    setAnunciosStatus(!anunciosStatus);
                    setCreateAnuncio(false);
                }
            }
            ).catch((error) => {
                console.log(error);
            });
        }
    }

    function deleteAnuncio(){
        fetch('https://back.ugt-sp.es/deleteAnuncio', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: data.id})
        }).then(results => {
            return results.json();
        }
        ).then(res => {
            if (res.deleted) {
                setAnunciosStatus(!anunciosStatus);
                setCreateAnuncio(false);
            }
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {    
        if (typeCreateAnuncio == 'edit') {
            setData(anuncio);
        } else {
            setData({
                title: '',
                description: '',
                // sede: '',
                ccaa: ''
            });
            setError({
                title: false,
                description: false,
                // sede: false,
                ccaa: false
            });
        }
    }, [createAnuncio])
    

    return (
        <>
            <Modal
                open={createAnuncio}
                onClose={() => setCreateAnuncio(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {typeCreateAnuncio == "edit" ? 
                        <Typography id="modal-modal-title" variant="h6" component="h2">Editar anuncio</Typography> 
                        :
                        <Typography id="modal-modal-title" variant="h6" component="h2">Crear anuncio</Typography>
                    }

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Introduce a continuación los datos del usuario:
                    </Typography>
                    {typeCreateAnuncio == "edit" ?
                        <div className="form-group mt-3">
                            <label htmlFor="user-name">ID</label>
                            <input type="text" className="form-control" id="user-dni" placeholder="ID" defaultValue={data.id} disabled />
                        </div>
                    :
                        null
                    }
                    <div className="form-group mt-3">
                        <label htmlFor="user-name">Título</label>
                        <input type="text" className="form-control" id="user-dni" placeholder="Introduce el título" defaultValue={data.title} onChange={e => setData({...data, title: e.target.value})} />
                        <div className={error.title ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir un título.
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="user-name">Descripción</label>
                        <input type="text" className="form-control" id="user-name" placeholder="Introduce la descripción" defaultValue={data.description} onChange={e => setData({...data, description: e.target.value})} />
                        <div className={error.description ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir una descripción.
                        </div>
                    </div>
                    {typeCreateAnuncio != "edit" ?
                        <div className="form-group mt-3">
                            <label htmlFor="user-comunidad">Comunidad Autónoma</label>
                            <Tags data={data} defaultValue={data.ccaa} changeFunction={setData} dataType="ccaa" all={true} />
                            <div className={error.ccaa ? "invalid-feedback d-block" : "invalid-feedback"}>
                                Debes introducir una comunidad.
                            </div>
                        </div>
                    :
                        <div className="form-group mt-3">
                            <label htmlFor="user-comunidad">Comunidad Autónoma</label>
                        
                            <div className={"invalid-feedback d-block"}>
                                No puedes editar la comunidad{/*  ni la sede.*/} <br /> Si quieres cambiar la comunidad{/* o la sede */}, elimina el anuncio y crea uno nuevo.
                            </div>
                        </div>
                    }
                    <div className="d-flex justify-content-between mt-4">
                        <button type="button" className={typeCreateAnuncio == "edit" ? "btn btn-dark w-33" : "btn btn-dark w-50 me-1" } onClick={() => setCreateAnuncio(false)}><CloseIcon/> Cancelar</button>
                        {typeCreateAnuncio == "edit" ? 
                            <>
                                <button type="button" className="btn btn-success w-33 ms-1" onClick={() => editAnuncio()}><SaveIcon/> Guardar</button>
                                <button type="button" className="btn btn-danger w-33 ms-1" onClick={() => setOpenDelete(true)}><Delete/> Borrar</button>
                            </>
                        :
                            <button type="button" className="btn btn-primary w-50 ms-1" onClick={() => createAnuncioBBDD()}><CheckIcon/> Crear</button>
                        }
                    </div>

                    <ConfirmDelete deleteFunction={deleteAnuncio} />
                </Box>
            </Modal>
        </>
    )
}
