import React, { useState, useEffect } from "react";
import { Link, Routes, Route} from 'react-router-dom';

import '../../styles/Employees.css';

import GroupIcon from '@mui/icons-material/Group';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import DomainIcon from '@mui/icons-material/Domain';
import List from "./sections/List";
import Sede from "./sections/Sede";
import { Solicitudes } from "./sections/Solicitudes";

export default function Employees(){
    const clearEmployeesNav = () => {
        setSedes(false);
        setEmployees(false);
        setSolicitudes(false);
    }

    const [hideLMenu, setHideLMenu] = useState(true);
    const [employees, setEmployees] = useState(true);
    const [sedes, setSedes] = useState(false);
    const [solicitudes, setSolicitudes] = useState(false);
        
    return (
  
        <div className="layout-lateral-menu fade-in">
            <div className="container-nav-menu">
                <div className={hideLMenu ? "nav-menu hide" : "nav-menu"}>
                    <Link to="lista"><div className={employees ? "nav-menu-button selected" : "nav-menu-button"} onClick={() => {clearEmployeesNav(); window.localStorage.setItem("url", "/empleados/lista"); setEmployees(true)}}>
                        <span className="icon"><GroupIcon fontSize="medium" /></span> Lista empleados
                    </div> </Link>

                    <Link to="sedes"> <div className={sedes ? "nav-menu-button selected" : "nav-menu-button"} onClick={() => {clearEmployeesNav(); window.localStorage.setItem("url", "/empleados/sedes"); setSedes(true)}}>
                        <span className="icon"><DomainIcon fontSize="medium" /></span> Sedes
                    </div> </Link>

                    <Link to="solicitudes"> <div className={solicitudes ? "nav-menu-button selected" : "nav-menu-button"} onClick={() => {clearEmployeesNav(); window.localStorage.setItem("url", "/empleados/solicitudes"); setSolicitudes(true)}}>
                        <span className="icon"><MarkEmailUnreadIcon fontSize="medium" /></span> Solicitudes
                    </div> </Link>

                    <div className="expand-content" onClick={() => {setHideLMenu(!hideLMenu)}}>
                        {hideLMenu ? <KeyboardArrowRightIcon/> : <KeyboardArrowLeftIcon/>}
                    </div>
                </div>
             
            </div>

            <Routes>
                <Route path="lista" element={<List />} />
                <Route path="sedes" element={<Sede />} />
                <Route path="solicitudes" element={<Solicitudes />} />
            </Routes>
        </div>

    );
}
