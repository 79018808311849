import React, {useState, useEffect} from 'react'
import { BsFillPersonPlusFill } from 'react-icons/bs';
import CreateUser from './components/CreateUser';
import { EnhancedTable } from './components/Table';
import { soCreateUser } from '../../common/useOpen';
import { rfUsers } from '../../common/useRefresh';

import useData from '../../common/useData'

export default function Users() {
    const {setCreateUser} = soCreateUser();
    const {userStatus} = rfUsers();
    const [users, setUsers] = useState([]);

    const {info} = useData();

    useEffect(() => {
        fetch("https://back.ugt-sp.es/getAllUsers",{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({dni: info.dni}),
        }).then(results => {
            return results.json();
        })
        .then(data => {
            setUsers(data.users);
        })
        .catch((error) => {
            console.log(error);
        });
    }, [userStatus])

    return (
        <div className="w-100 bg-grey scroll-y p-2 pt-3">
            <div className="container-fluid fade-in">
                <div className="row">
                    <div className="col-sm-6">
                        <h1>Usuarios</h1>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-end pt-2 pb-2">
                        <button type="button" className="btn btn-primary" onClick={() => {
                            setCreateUser(true, null)
                        }}><BsFillPersonPlusFill /> Crear</button>
                    </div>
                    {/* <div className="col-12">
                        <input type="search" className="form-control ps-2" placeholder="Buscar usuario..." onChange={(e) => {
                            setSearchEmployees(e.target.value)
                        }}></input>
                    </div> */}
                </div>
            
                <div className='appendEmployeesDiv mt-3'>
                    <CreateUser />
                    
                    {users.length > 0 && users ? 
                        <EnhancedTable data={users} />
                    :
                        <EnhancedTable data={[]} />
                    }
                </div>
            </div>
        </div>
    )
}