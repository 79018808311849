import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

import { rfSedes } from '../../../common/useRefresh';

import {soCreateSede, soSede} from '../../../common/useOpen';
import { Delete } from '@mui/icons-material';
import SelectCCAA from '../../common/SelectCCAA';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 340,
  maxWidth:700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  maxHeight: '90%'
};

export default function CreateSede() {
    const {sedesStatus, setSedesStatus} = rfSedes();
    const {createSede, typeCreateSede, setCreateSede} = soCreateSede();
    const {sede} = soSede();

    const [data, setData] = useState({
        id: null,
        nombre: '',
        ccaa: '',
        direccion: '',
        cp: '',
        provincia: '',
        localidad: '',
        telefono: '',
        email: '',
        horasMensuales: 0,
        horasAnuales: 0,
        vacaciones: 0,
        libres: 0
    });

    const [error, setError] = useState({
        nombre: false,
        ccaa: false,
        direccion: false,
        cp: false,
        provincia: false,
        localidad: false,
        telefono: false,
        email: false,
        horasMensuales: false,
        horasAnuales: false,
        vacaciones: false,
        libres: false
    });

    function createSedeBBDD() {
        let error = false;
        const errorFounded = {
            nombre: false,
            ccaa: false,
            direccion: false,
            cp: false,
            provincia: false,
            localidad: false,
            telefono: false,
            email: false,
            horasMensuales: false,
            horasAnuales: false,
            vacaciones: false,
            libres: false
        };

        if (data.nombre == '') {
            errorFounded.nombre = true;
            error = true;
        }
        if (data.ccaa == '') {
            errorFounded.ccaa = true;
            error = true;
        }
        if (data.direccion == '') {
            errorFounded.direccion = true;
            error = true;
        }
        if (data.cp == '') {
            errorFounded.cp = true;
            error = true;
        }
        if (data.provincia == '') {
            errorFounded.provincia = true;
            error = true;
        }
        if (data.localidad == '') {
            errorFounded.localidad = true;
            error = true;
        }
        if (data.telefono == '') {
            errorFounded.telefono = true;
            error = true;
        }
        if (data.email == '') {
            errorFounded.email = true;
            error = true;
        }
        if (data.horasMensuales == '') {
            errorFounded.horasMensuales = true;
            error = true;
        }
        if (data.horasAnuales == '') {
            errorFounded.horasAnuales = true;
            error = true;
        }
        if (data.vacaciones == '') {
            errorFounded.vacaciones = true;
            error = true;
        }
        if (data.libres == '') {
            errorFounded.libres = true;
            error = true;
        }

        setError(errorFounded);

        if (!error) {
            fetch('https://back.ugt-sp.es/createSede', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(results => {
                return results.json();
            }
            ).then(res => {
                if (res.status == 'ok') {
                    setSedesStatus(!sedesStatus);
                    setCreateSede(false);
                }
            }
            ).catch((error) => {
                console.log(error);
            });
        }
    }

    function editSede(){
        let error = false;
        const errorFounded = {
            nombre: false,
            ccaa: false,
            direccion: false,
            cp: false,
            provincia: false,
            localidad: false,
            telefono: false,
            email: false,
            horasMensuales: false,
            horasAnuales: false,
            vacaciones: false,
            libres: false
        };

        if (data.nombre == '') {
            errorFounded.nombre = true;
            error = true;
        }
        if (data.ccaa == '') {
            errorFounded.ccaa = true;
            error = true;
        }
        if (data.direccion == '') {
            errorFounded.direccion = true;
            error = true;
        }
        if (data.cp == '') {
            errorFounded.cp = true;
            error = true;
        }
        if (data.provincia == '') {
            errorFounded.provincia = true;
            error = true;
        }
        if (data.localidad == '') {
            errorFounded.localidad = true;
            error = true;
        }
        if (data.telefono == '') {
            errorFounded.telefono = true;
            error = true;
        }
        if (data.email == '') {
            errorFounded.email = true;
            error = true;
        }
        if (data.horasMensuales == '') {
            errorFounded.horasMensuales = true;
            error = true;
        }
        if (data.horasAnuales == '') {
            errorFounded.horasAnuales = true;
            error = true;
        }
        if (data.vacaciones == '') {
            errorFounded.vacaciones = true;
            error = true;
        }
        if (data.libres == '') {
            errorFounded.libres = true;
            error = true;
        }

        setError(errorFounded);

        if (!error) {
            fetch('https://back.ugt-sp.es/updateSede', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(results => {
                return results.json();
            }
            ).then(res => {
                if (res.status == 'ok') {
                    setSedesStatus(!sedesStatus);
                    setCreateSede(false);
                }
            }
            ).catch((error) => {
                console.log(error);
            });
        }
    }

    useEffect(() => {    
        if (typeCreateSede == 'edit') {
            setData(sede);
            console.log(sede)
        } else {
            setData({
                id: null,
                nombre: '',
                ccaa: '',
                direccion: '',
                cp: '',
                provincia: '',
                localidad: '',
                telefono: '',
                email: '',
                horasMensuales: '',
                horasAnuales: '',
                vacaciones: '',
                libres: ''
            });
            setError({
                nombre: false,
                ccaa: false,
                direccion: false,
                cp: false,
                provincia: false,
                localidad: false,
                telefono: false,
                email: false,
                horasMensuales: false,
                horasAnuales: false,
                vacaciones: false,
                libres: false
            });
        }
    }, [createSede])
    
    return (
        <>
            <Modal
                open={createSede}
                onClose={() => setCreateSede(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {typeCreateSede == "edit" ? 
                        <Typography id="modal-modal-title" variant="h6" component="h2">Editar sede</Typography> 
                        :
                        <Typography id="modal-modal-title" variant="h6" component="h2">Crear sede</Typography>
                    }
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Introduce a continuación los datos de la sede:
                    </Typography>
                    <div className="form-group mt-3">
                        <label htmlFor="employee-name">Nombre</label>
                        <input type="text" className="form-control" id="employee-name" placeholder="Introduce el nombre" defaultValue={data.nombre} onChange={e => setData({...data, nombre: e.target.value})} />
                        <div className={error.nombre ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir un nombre.
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="employee-comunidad">Comunidad Autónoma</label>
                        <SelectCCAA data={data} defaultValue={data.ccaa} changeFunction={setData} dataType="ccaa" />
                        <div className={error.ccaa ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir una comunidad.
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="employee-address">Dirección</label>
                        <input type="text" className="form-control" id="employee-address" placeholder="Introduce la dirección" defaultValue={data.direccion} onChange={e => setData({...data, direccion: e.target.value})} />
                        <div className={error.direccion ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir una dirección.
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="employee-cp">Código Postal</label>
                        <input type="number" min="0" className="form-control" id="employee-cp" placeholder="Introduce el código postal" defaultValue={data.cp} onChange={e => setData({...data, cp: e.target.value})} />
                        <div className={error.cp ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir un código postal.
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="employee-provincia">Provincia</label>
                        <input type="text" className="form-control" id="employee-provincia" placeholder="Introduce la provincia" defaultValue={data.provincia} onChange={e => setData({...data, provincia: e.target.value})} />
                        <div className={error.provincia ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir una provincia.
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="employee-localidad">Localidad</label>
                        <input type="text" className="form-control" id="employee-localidad" placeholder="Introduce la población" defaultValue={data.localidad} onChange={e => setData({...data, localidad: e.target.value})} />
                        <div className={error.localidad ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir una localidad.
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="employee-email">Email</label>
                        <input type="email" className="form-control" id="employee-email" placeholder="Introduce el email" defaultValue={data.email} onChange={e => setData({...data, email: e.target.value})} />
                        <div className={error.email ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir un email.
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="employee-telefono">Teléfono</label>
                        <input type="number" min="0" className="form-control" id="employee-telefono" placeholder="Introduce el teléfono" defaultValue={data.telefono} onChange={e => setData({...data, telefono: e.target.value})} />
                        <div className={error.telefono ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir un teléfono.
                        </div>
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="employee-horasMensuales">Horas mensuales</label>
                        <input type="number" min="0" className="form-control" id="employee-mensuales" placeholder="Introduce las horas mensuales" defaultValue={data.horasMensuales} onChange={e => setData({...data, horasMensuales: e.target.value})} />
                        <div className={error.horasMensuales ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir un número.
                        </div>
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="employee-horasAnuales">Horas anuales</label>
                        <input type="number" min="0" className="form-control" id="employee-anuales" placeholder="Introduce las horas anuales" defaultValue={data.horasAnuales} onChange={e => setData({...data, horasAnuales: e.target.value})} />
                        <div className={error.horasAnuales ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir un número.
                        </div>
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="employee-telefono">Días de vacaciones anuales</label>
                        <input type="number" min="0" className="form-control" id="employee-vacanuales" placeholder="Introduce el nº de días de vacaciones anuales" defaultValue={data.vacaciones} onChange={e => setData({...data, vacaciones: e.target.value})} />
                        <div className={error.vacaciones ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir un número.
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="employee-telefono">Días libres anuales</label>
                        <input type="number" min="0" className="form-control" id="employee-diasanuales" placeholder="Introduce el nº de días libres anuales" defaultValue={data.libres} onChange={e => setData({...data, libres: e.target.value})} />
                        <div className={error.libres ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir un número.
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                        <button type="button" className={typeCreateSede == "edit" ? "btn btn-dark w-33" : "btn btn-dark w-50 me-1" } onClick={() => setCreateSede(false)}><CloseIcon/> Cancelar</button>
                        {typeCreateSede == "edit" ? 
                            <>
                                <button type="button" className="btn btn-success w-33 ms-1" onClick={() => editSede()}><SaveIcon/> Guardar</button>
                            </>
                        :
                            <button type="button" className="btn btn-primary w-50 ms-1" onClick={() => createSedeBBDD()}><CheckIcon/> Crear</button>
                        }
                    </div>
                </Box>
            </Modal>
        </>
    )
}
