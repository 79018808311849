import create from 'zustand';

const rfEmployees = create(set => ({
    employeeStatus: false,
    setEmployeeStatus: (status) => set(state => ({ employeeStatus: status })),
}))

const rfSedes = create(set => ({
    sedesStatus: false,
    setSedesStatus: (status) => set(state => ({ sedesStatus: status })),
}))

const rfFichajes = create(set => ({
    fichajesStatus: false,
    setFichajesStatus: (status) => set(state => ({ fichajesStatus: status })),
}))

const rfUsers = create(set => ({
    userStatus: false,
    setUserStatus: (status) => set(state => ({ userStatus: status })),
}))

const rfAnuncios = create(set => ({
    anunciosStatus: false,
    setAnunciosStatus: (status) => set(state => ({ anunciosStatus: status })),
}))

const rfSoliciudes = create(set => ({
    solicitudesStatus: false,
    setSolicitudesStatus: (status) => set(state => ({ solicitudesStatus: status })),
}))

export {rfEmployees, rfSedes, rfFichajes, rfUsers, rfAnuncios, rfSoliciudes};