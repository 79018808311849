import React, { useState } from 'react'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { soModalRefused } from './useOpen';

export function ModalRefused({refusedFunction, message}) {
    const {openRefused, setOpenRefused} = soModalRefused();
    const [motivo, setMotivo] = useState("");

    return (
        <div className="w-100 bg-grey scroll-y">
             <Modal
                open={openRefused}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="scale-in bg-modal-incident d-flex justify-content-center align-items-center">
                    <Box className="scale-in modal-close-incident">
                        <CloseIcon className="closeModal" onClick={() => setOpenRefused(false)} />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {message ? message : "Motivo por el que se rechaza la solicitud"}
                        </Typography>

                        <textarea className="w-100" rows="5" placeholder="Escribe el motivo" onChange={(e) => {setMotivo(e.target.value)}}></textarea>

                    <button className="btn btn-danger w-100 mt-2" onClick={() => {
                        console.log(motivo)
                        refusedFunction(motivo);
                        setOpenRefused(false);
                    }}>Enviar</button>
                </Box>
            </Modal>
        </div>
    )
}
