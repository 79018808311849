import create from 'zustand';

const soCreateEmployee = create(set => ({
    createEmployee: false,
    typeCreateEmployee: '',
    setCreateEmployee: (status, typeCreateEmployee) => set(state => ({ createEmployee: status, typeCreateEmployee: typeCreateEmployee })),
}))

const soEmployee = create(set => ({
    employee: {},
    setEmployee: (employee) => set(state => ({ employee: employee })),
}))

const soCreateSede = create(set => ({
    createSede: false,
    typeCreateSede: '',
    setCreateSede: (status, typeCreateSede) => set(state => ({ createSede: status, typeCreateSede: typeCreateSede })),
}))

const soConfirmDelete = create(set => ({
    openDelete: false,
    setOpenDelete: (status) => set(state => ({ openDelete: status })),
}))

const soModalRefused = create(set => ({
    openRefused: false,
    setOpenRefused: (status) => set(state => ({ openRefused: status })),
}))

const soInfoFichajes = create(set => ({
    openInfoModal: false,
    setOpenInfoModal: (status) => set(state => ({ openInfoModal: status })),
}))

const soSede = create(set => ({
    sede: {},
    setSede: (sede) => {set(state => ({ sede: sede }))},
}))

const soCreateUser = create(set => ({
    createUser: false,
    typeCreateUser: '',
    setCreateUser: (status, typeCreateUser) => set(state => ({ createUser: status, typeCreateUser: typeCreateUser })),
}))

const soCreateFichajes = create(set => ({
    createFichaje: false,
    setCreateFichaje: (status) => set(state => ({ createFichaje: status })),
}))

const soUser = create(set => ({
    user: {},
    setUser: (employee) => set(state => ({ user: employee })),
}))

const soCreateAnuncio = create(set => ({
    createAnuncio: false,
    typeCreateAnuncio: '',
    setCreateAnuncio: (status, typeCreateAnuncio) => set(state => ({ createAnuncio: status, typeCreateAnuncio: typeCreateAnuncio })),
}))

const soAnuncio = create(set => ({
    anuncio: {},
    setAnuncio: (anuncio) => set(state => ({ anuncio: anuncio })),
}))

export {soCreateEmployee, soEmployee, soConfirmDelete, soModalRefused, soCreateSede, soInfoFichajes, soSede, soCreateUser, soCreateFichajes, soUser, soCreateAnuncio, soAnuncio};