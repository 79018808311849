import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

import { rfUsers } from '../../../common/useRefresh';
import { useRol } from '../../../common/useRol';

import { soCreateUser, soUser, soConfirmDelete } from '../../../common/useOpen';
import { Delete } from '@mui/icons-material';
import SelectCCAA from '../../common/SelectCCAA';
import SelectSedes from '../../common/SelectSedes';
import { ConfirmDelete } from '../../../common/ModalConfirmDelete';

import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: 340,
	maxWidth: 700,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	overflowY: 'auto',
	maxHeight: '90%'
};

export default function CreateUser() {
	const { userStatus, setUserStatus } = rfUsers();
	const { createUser, typeCreateUser, setCreateUser } = soCreateUser();
	const { setOpenDelete } = soConfirmDelete();

	const { rol } = useRol();

	const { user } = soUser();

	const [data, setData] = useState({
		dni: '',
		nombre: '',
		apellidos: '',
		ccaa: '',
		sede: [],
		direccion: '',
		cp: '',
		provincia: '',
		localidad: '',
		telefono: '',
		email: '',
		rol: ''
	});

	const [error, setError] = useState({
		dni: false,
		nombre: false,
		apellidos: false,
		ccaa: false,
		sede: false,
		direccion: false,
		cp: false,
		provincia: false,
		localidad: false,
		telefono: false,
		email: false,
		rol: false
	});

	function createUserBBDD() {
		let error = false;
		const errorFounded = {
			dni: false,
			nombre: false,
			apellidos: false,
			ccaa: false,
			sede: false,
			direccion: false,
			cp: false,
			provincia: false,
			localidad: false,
			telefono: false,
			email: false,
			rol: false
		};

		if (data.dni == '') {
			errorFounded.dni = true;
			error = true;
		}
		if (data.nombre == '') {
			errorFounded.nombre = true;
			error = true;
		}
		if (data.apellidos == '') {
			errorFounded.apellidos = true;
			error = true;
		}
		if (data.ccaa == '') {
			errorFounded.ccaa = true;
			error = true;
		}
		if (data.sede == []) {
			errorFounded.sede = true;
			error = true;
		}
		if (data.direccion == '') {
			errorFounded.direccion = true;
			error = true;
		}
		if (data.cp == '') {
			errorFounded.cp = true;
			error = true;
		}
		if (data.provincia == '') {
			errorFounded.provincia = true;
			error = true;
		}
		if (data.localidad == '') {
			errorFounded.localidad = true;
			error = true;
		}
		if (data.telefono == '') {
			errorFounded.telefono = true;
			error = true;
		}
		if (data.email == '') {
			errorFounded.email = true;
			error = true;
		}
		if (data.rol == '') {
			errorFounded.rol = true;
			error = true;
		}

		setError(errorFounded);

		if (!error) {
			fetch('https://back.ugt-sp.es/createUserDev', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			})
				.then((results) => {
					return results.json();
				})
				.then((res) => {
					if (res.status == 'ok') {
						setUserStatus(!userStatus);
						setCreateUser(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	function editUser() {
		let error = false;
		const errorFounded = {
			dni: false,
			nombre: false,
			apellidos: false,
			ccaa: false,
			sede: false,
			direccion: false,
			cp: false,
			provincia: false,
			localidad: false,
			telefono: false,
			email: false,
			rol: false
		};

		if (data.dni == '') {
			errorFounded.dni = true;
			error = true;
		}
		if (data.nombre == '') {
			errorFounded.nombre = true;
			error = true;
		}
		if (data.apellidos == '') {
			errorFounded.apellidos = true;
			error = true;
		}
		if (data.ccaa == '') {
			errorFounded.ccaa = true;
			error = true;
		}
		if (data.sede == []) {
			errorFounded.sede = true;
			error = true;
		}
		if (data.direccion == '') {
			errorFounded.direccion = true;
			error = true;
		}
		if (data.cp == '') {
			errorFounded.cp = true;
			error = true;
		}
		if (data.provincia == '') {
			errorFounded.provincia = true;
			error = true;
		}
		if (data.localidad == '') {
			errorFounded.localidad = true;
			error = true;
		}
		if (data.telefono == '') {
			errorFounded.telefono = true;
			error = true;
		}
		if (data.email == '') {
			errorFounded.email = true;
			error = true;
		}
		if (data.rol == '') {
			errorFounded.rol = true;
			error = true;
		}

		setError(errorFounded);

		if (!error) {
			fetch('https://back.ugt-sp.es/updateUser', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			})
				.then((results) => {
					return results.json();
				})
				.then((res) => {
					if (res.status == 'ok') {
						setUserStatus(!userStatus);
						setCreateUser(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	function deleteUser() {
		fetch('https://back.ugt-sp.es/deleteUser', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ dni: data.dni })
		})
			.then((results) => {
				return results.json();
			})
			.then((res) => {
				if (res.status == 'ok') {
					setUserStatus(!userStatus);
					setCreateUser(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	useEffect(() => {
		if (typeCreateUser == 'edit') {
			setData(user);
			console.log(user);
		} else {
			setData({
				dni: '',
				nombre: '',
				apellidos: '',
				ccaa: '',
				sede: [],
				direccion: '',
				cp: '',
				provincia: '',
				localidad: '',
				telefono: '',
				email: '',
				rol: ''
			});
			setError({
				dni: false,
				nombre: false,
				apellidos: false,
				ccaa: false,
				sede: false,
				direccion: false,
				cp: false,
				provincia: false,
				localidad: false,
				telefono: false,
				email: false,
				rol: false
			});
		}
	}, [createUser]);

	return (
		<>
			<Modal
				open={createUser}
				onClose={() => setCreateUser(false)}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					{typeCreateUser == 'edit' ? (
						<Typography id='modal-modal-title' variant='h6' component='h2'>
							Editar usuario
						</Typography>
					) : (
						<Typography id='modal-modal-title' variant='h6' component='h2'>
							Crear usuario
						</Typography>
					)}

					<Typography id='modal-modal-description' sx={{ mt: 2 }}>
						Introduce a continuación los datos del usuario:
					</Typography>
					<div className='form-group mt-3'>
						<label htmlFor='user-name'>DNI</label>
						<input
							type='text'
							className='form-control'
							id='user-dni'
							placeholder='Introduce el DNI'
							defaultValue={data.dni}
							disabled={typeCreateUser == 'edit'}
							onChange={(e) => setData({ ...data, dni: e.target.value })}
						/>
						<div
							className={
								error.dni
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir un DNI.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='user-name'>Nombre</label>
						<input
							type='text'
							className='form-control'
							id='user-name'
							placeholder='Introduce el nombre'
							defaultValue={data.nombre}
							onChange={(e) => setData({ ...data, nombre: e.target.value })}
						/>
						<div
							className={
								error.nombre
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir un nombre.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='user-surname'>Apellidos</label>
						<input
							type='text'
							className='form-control'
							id='user-surname'
							placeholder='Introduce los apellidos'
							defaultValue={data.apellidos}
							onChange={(e) =>
								setData({ ...data, apellidos: e.target.value })
							}
						/>
						<div
							className={
								error.apellidos
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir un apellido.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='user-comunidad'>Comunidad Autónoma</label>
						<SelectCCAA
							data={data}
							defaultValue={data.ccaa}
							changeFunction={setData}
							dataType='ccaa'
						/>
						{/* <select className="form-select" id="user-comunidad" value={data.ccaa} onChange={e => setData({...data, ccaa: e.target.value})} >
                                <option selected disabled>Introduce la Comunidad Autónoma</option>
                                <option value="Madrid">Madrid</option>
                                <option value="Galicia">Galicia</option>
                                <option value="Andalucía">Andalucía</option>
                                <option value="Otras">Otras</option>
                            </select> */}
						<div
							className={
								error.ccaa
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir una comunidad.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='user-sede'>Sede</label>
						<SelectSedes
							data={data}
							defaultValue={data.sede}
							changeFunction={setData}
							dataType='sede'
						/>
						{/* <select className="form-select" id="user-sede" value={data.sede} onChange={e => setData({...data, sede: e.target.value})} >
                                <option selected disabled>Introduce la sede</option>
                                <option value="1">Sede 1</option>
                                <option value="2">Sede 2</option>
                                <option value="3">Sede 3</option>
                                <option value="3">Sede 4</option>
                            </select> */}
						<div
							className={
								error.sede
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir una sede.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='user-address'>Dirección</label>
						<input
							type='text'
							className='form-control'
							id='user-address'
							placeholder='Introduce la dirección'
							defaultValue={data.direccion}
							onChange={(e) =>
								setData({ ...data, direccion: e.target.value })
							}
						/>
						<div
							className={
								error.direccion
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir una dirección.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='user-cp'>Código Postal</label>
						<input
							type='number'
							min='0'
							className='form-control'
							id='user-cp'
							placeholder='Introduce el código postal'
							defaultValue={data.cp}
							onChange={(e) => setData({ ...data, cp: e.target.value })}
						/>
						<div
							className={
								error.cp ? 'invalid-feedback d-block' : 'invalid-feedback'
							}
						>
							Debes introducir un código postal.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='user-provincia'>Provincia</label>
						<input
							type='text'
							className='form-control'
							id='user-provincia'
							placeholder='Introduce la provincia'
							defaultValue={data.provincia}
							onChange={(e) =>
								setData({ ...data, provincia: e.target.value })
							}
						/>
						<div
							className={
								error.provincia
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir una provincia.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='user-localidad'>Localidad</label>
						<input
							type='text'
							className='form-control'
							id='user-localidad'
							placeholder='Introduce la población'
							defaultValue={data.localidad}
							onChange={(e) =>
								setData({ ...data, localidad: e.target.value })
							}
						/>
						<div
							className={
								error.localidad
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir una localidad.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='user-email'>Email</label>
						<input
							type='email'
							className='form-control'
							id='user-email'
							placeholder='Introduce el email'
							defaultValue={data.email}
							onChange={(e) => setData({ ...data, email: e.target.value })}
						/>
						<div
							className={
								error.email
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir un email.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='user-telefono'>Teléfono</label>
						<input
							type='number'
							min='0'
							className='form-control'
							id='user-telefono'
							placeholder='Introduce el teléfono'
							defaultValue={data.telefono}
							onChange={(e) =>
								setData({ ...data, telefono: e.target.value })
							}
						/>
						<div
							className={
								error.telefono
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir un teléfono.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='user-rol'>Rol</label>
						<select
							className='form-select'
							id='user-rol'
							value={typeCreateUser == 'edit' ? data.rol : null}
							onChange={(e) => setData({ ...data, rol: e.target.value })}
						>
							<option selected disabled>
								Introduce el rol
							</option>
							{rol === 'Administrador' ? (
								<>
									<option value='Administrador'>Administrador</option>
									<option value='Responsable_ccaa'>
										Responsable CCAA
									</option>
								</>
							) : null}
							<option value='Responsable_sede'>Responsable Sede</option>
						</select>
						<div
							className={
								error.rol
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes seleccionar un rol.
						</div>
					</div>
					<div
						className='mt-2'
						style={{
							fontSize: '15px',
							textDecoration: 'underline',
							cursor: 'pointer',
							color: 'var(--color-primary)'
						}}
						onClick={() => {
							fetch('https://back.ugt-sp.es/resetUserPassword', {
								method: 'POST',
								headers: {
									'Content-Type': 'application/json'
								},
								body: JSON.stringify({ dni: data.dni })
							})
								.then((results) => {
									return results.json();
								})
								.then((res) => {
									if (res.updated) {
										NotificationManager.success(
											'Contraseña reseteada correctamente',
											'Contraseña reseteada',
											3000
										);
										setCreateUser(false);
									}
								})
								.catch((error) => {
									NotificationManager.error(
										'Error al resetear la contraseña',
										'Error',
										3000
									);
									console.log(error);
								});
						}}
					>
						Restablecer Contraseña
					</div>
					<div className='d-flex justify-content-between mt-4'>
						<button
							type='button'
							className={
								typeCreateUser == 'edit'
									? 'btn btn-dark w-33'
									: 'btn btn-dark w-50 me-1'
							}
							onClick={() => setCreateUser(false)}
						>
							<CloseIcon /> Cancelar
						</button>
						{typeCreateUser == 'edit' ? (
							<>
								<button
									type='button'
									className='btn btn-success w-33 ms-1'
									onClick={() => editUser()}
								>
									<SaveIcon /> Guardar
								</button>
								<button
									type='button'
									className='btn btn-danger w-33 ms-1'
									onClick={() => setOpenDelete(true)}
								>
									<Delete /> Borrar
								</button>
							</>
						) : (
							<button
								type='button'
								className='btn btn-primary w-50 ms-1'
								onClick={() => createUserBBDD()}
							>
								<CheckIcon /> Crear
							</button>
						)}
					</div>

					<ConfirmDelete deleteFunction={deleteUser} />
				</Box>
			</Modal>
		</>
	);
}
