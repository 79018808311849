import React from 'react'
import { rfFichajes } from '../../../common/useRefresh';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { soInfoFichajes } from '../../../common/useOpen';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LockClockIcon from '@mui/icons-material/LockClock';
import HistoryIcon from '@mui/icons-material/History';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useData from '../../../common/useData';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

export function ModanInfo({ user }) {
    const { info } = useData();
    const { openInfoModal, setOpenInfoModal } = soInfoFichajes();
    const { fichajesStatus, setFichajesStatus } = rfFichajes();
    const [edit, setEdit] = React.useState(false);

    const [data, setData] = React.useState({
        id: user.id,
        entrada: user.entrada,
        salida: user.salida,
        horas: user.horas,
        descansos: user.descansos,
        especial: user.especial,
        admin: info.dni
    });

    React.useEffect(() => {
        setEdit(false);

        setData({
            id: user.id,
            entrada: user.entrada,
            salida: user.salida,
            horas: user.horas,
            descansos: user.descansos,
            especial: user.especial,
            admin: info.dni
        })
    }, [user])

    return (
        <div className="w-100 bg-grey scroll-y">
            <Modal
                open={openInfoModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CloseIcon className="closeModal" onClick={() => setOpenInfoModal(false)} />
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Información de fichaje #{user.id}
                    </Typography>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <label className="d-block">Nombre</label>
                            <div className="badge bg-label">{user.nombre}</div>
                            <label className="d-block mt-2">Apellidos</label>
                            <div className="badge bg-label">{user.apellidos}</div>
                            <label className="d-block mt-2">Comunidad Autónoma</label>
                            <div className="badge bg-label">{user.ccaa}</div>
                            <label className="d-block mt-2">Sede</label>
                            <div className="badge bg-label">{user.sede}</div>

                        </div>
                        <div className="col-12 col-md-6">
                            {!edit ? (
                                <>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="rounded bg-label p-2 text-center text-muted">
                                                <CalendarMonthIcon style={{ fontSize: "50px" }} />
                                                <label className="d-block">Fecha</label>
                                                <div>{new Date(data.entrada).toLocaleDateString()}</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="rounded bg-label p-2 text-center text-muted">
                                                <LockClockIcon style={{ fontSize: "50px" }} />
                                                <label className="d-block">Horas Trabajadas</label>
                                                <div>{data.horas}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 mt-3">
                                            <div className="rounded bg-label p-2 text-center text-muted">
                                                <UpdateIcon style={{ fontSize: "50px" }} />
                                                <label className="d-block">Entrada</label>
                                                <div>{new Date(data.entrada).toLocaleTimeString()}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 mt-3">
                                            <div className="rounded bg-label p-2 text-center text-muted">
                                                <HistoryIcon style={{ fontSize: "50px" }} />
                                                <label className="d-block">Salida</label>
                                                <div>{new Date(data.salida).toLocaleTimeString()}</div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            ) : (
                                <>
                                    <label className="d-block">Hora de entrada</label>
                                    <TextField
                                        id="outlined-basic"
                                        label="Entrada"
                                        type={"datetime-local"}
                                        defaultValue={new Date(data.entrada).getFullYear() + "-" + Number(new Date(data.entrada).getMonth() + 1) + "-" + new Date(data.entrada).getDate() + "T" + new Date(data.entrada).getHours() + ":" + new Date(data.entrada).getMinutes()}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={e => setData({ ...data, entrada: e.target.value })}
                                        className="w-100"
                                    />
                                    <label className="d-block mt-4">Hora de salida</label>
                                    <TextField
                                        id="outlined-basic"
                                        label="Salida"
                                        type={"datetime-local"}
                                        defaultValue={new Date(data.salida).getFullYear() + "-" + Number(new Date(data.salida).getMonth() + 1) + "-" + new Date(data.salida).getDate() + "T" + new Date(data.salida).getHours() + ":" + new Date(data.salida).getMinutes()}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={e => setData({ ...data, salida: e.target.value })}
                                        className="w-100"
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12 col-md-6">
                            <label className="d-block">Comentarios Entrada</label>
                            <div className="bg-label p-3 rounded break">{user.observaciones_entrada || "Sin Comentarios"}</div>
                        </div>
                        <div className="col-12 col-md-6">
                            <label className="d-block">Comentarios Salida</label>
                            <div className="bg-label p-3 rounded break">{user.observaciones_salida || "Sin Comentarios"}</div>

                        </div>
                    </div>
                    <div className="mt-3">
                        <div>
                            <label className="d-block">Descansos</label>
                            {data.descansos != null ? (

                                <div className='d-flex mb-3 flex-wrap'>
                                    {JSON.parse(data.descansos).map((descanso, index) => {
                                        return (
                                            <div key={index} className="me-1 mb-1 p-2 badge bg-label text-start">
                                                <label className="d-block mb-2">Descanso {index + 1}</label>
                                                <div><ArrowForwardIcon fontSize="small" className="text-success" /> <span className="text-muted">{new Date(descanso.entrada).getHours() + ":" + new Date(descanso.entrada).getMinutes()}</span></div>
                                                <div><ArrowBackIcon fontSize="small" className="text-danger" /> <span className="text-muted">{new Date(descanso.salida).getHours() + ":" + new Date(descanso.entrada).getMinutes()}</span></div>
                                            </div>
                                        )
                                    })}
                                </div>)
                                :
                                <div className="badge bg-label">Sin descansos</div>
                            }
                        </div>

                        <div>
                            <label className="d-block mt-3">Citas médicas y compensaciones</label>
                            {data.especial != null ? (
                                <div className='d-flex mb-3 flex-wrap'>
                                    {JSON.parse(data.especial).map((especial, index) => {
                                        return (
                                            <div key={index} className="me-1 mb-1 p-2 badge bg-label text-start">
                                                <label className="d-block mb-2">{especial.tipo == "medico" ? "Médico" : "Compensación"}</label>
                                                <div><ArrowForwardIcon fontSize="small" className="text-success" /> <span className="text-muted">{new Date(especial.entrada).getHours() + ":" + new Date(especial.entrada).getMinutes()}</span></div>
                                                <div><ArrowBackIcon fontSize="small" className="text-danger" /> <span className="text-muted">{new Date(especial.salida).getHours() + ":" + new Date(especial.entrada).getMinutes()}</span></div>
                                                {especial.tipo == "compensacion" ? <div style={{ fontSize: "13px" }} className="d-flex align-items-center w-100 justify-content-center"><CalendarMonthIcon style={{ fontSize: "15px" }} className="text-muted" /> <span className="text-muted">{new Date(especial.date).toLocaleDateString()}</span></div> : null}
                                            </div>
                                        )
                                    })}
                                </div>)
                                :
                                <div className="badge bg-label">Sin citas médicas o compensaciones</div>

                            }
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12 col-md-6">
                            <button className="btn btn-danger w-100 mt-2" onClick={() => {
                                fetch('https://back.ugt-sp.es/deleteFichaje', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        id: data.id,
                                    })
                                }).then(results => {
                                    return results.json();
                                }
                                ).then(res => {
                                    setFichajesStatus(!fichajesStatus);
                                    setOpenInfoModal(false);
                                }
                                ).catch((error) => {
                                    console.log(error);
                                });
                            }}>Eliminar</button>
                        </div>
                        <div className="col-12 col-md-6">
                            {!edit ? (
                                <button className="btn btn-success w-100 mt-2" onClick={() => {
                                    setEdit(true);
                                }}>Editar</button>
                            ) : (
                                <button className="btn btn-success w-100 mt-2" onClick={() => {
                                    // Obtener la fecha de entrada y salida
                                    let fechaEntrada = new Date(data.entrada);
                                    let fechaSalida = new Date(data.salida);

                                    // Obtener horas, minutos y segundos de entrada y salida
                                    let hourEntrada = fechaEntrada.getHours();
                                    let minuteEntrada = fechaEntrada.getMinutes();
                                    let secondsEntrada = fechaEntrada.getSeconds();

                                    let hourSalida = fechaSalida.getHours();
                                    let minuteSalida = fechaSalida.getMinutes();
                                    let secondsSalida = fechaSalida.getSeconds();

                                    // Convertir la hora de entrada y salida a segundos totales
                                    let totalSecondsEntrada = hourEntrada * 3600 + minuteEntrada * 60 + secondsEntrada;
                                    let totalSecondsSalida = hourSalida * 3600 + minuteSalida * 60 + secondsSalida;

                                    // Manejar el caso donde la salida es anterior a la entrada
                                    if (totalSecondsSalida < totalSecondsEntrada) {
                                        totalSecondsSalida += 24 * 3600; // Agregar un día en segundos
                                    }

                                    // Calcular la diferencia en segundos
                                    let totalSeconds = totalSecondsSalida - totalSecondsEntrada;

                                    // Convertir los segundos totales en horas y minutos
                                    let horas = Math.floor(totalSeconds / 3600);
                                    let minutos = Math.floor((totalSeconds % 3600) / 60);

                                    // Formatear la salida
                                    let total = (horas < 10 ? "0" + horas : horas) + ":" + (minutos < 10 ? "0" + minutos : minutos);

                                    setData({ ...data, horas: total });

                                    setTimeout(() => {
                                        fetch('https://back.ugt-sp.es/updateFichaje', {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify(data)
                                        }).then(results => {
                                            return results.json();
                                        }
                                        ).then(res => {
                                            setFichajesStatus(!fichajesStatus);
                                            setEdit(false);
                                        }
                                        ).catch((error) => {
                                            console.log(error);
                                        });
                                    }, 500);
                                }}>Guardar</button>
                            )}
                        </div>


                    </div>
                </Box>
            </Modal>
        </div>
    )
}
