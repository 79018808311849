import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Collapse from 'react-bootstrap/Collapse';

import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import { rfEmployees } from '../../../common/useRefresh';

import { soCreateEmployee, soEmployee, soConfirmDelete } from '../../../common/useOpen';
import { Check, Delete } from '@mui/icons-material';
import SelectCCAA from '../../common/SelectCCAA';
import SelectSede from '../../common/SelectSede';
import { ConfirmDelete } from '../../../common/ModalConfirmDelete';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: 340,
	maxWidth: 700,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	overflowY: 'auto',
	maxHeight: '90%'
};

export default function CreateEmployee() {
	const { employeeStatus, setEmployeeStatus } = rfEmployees();
	const { createEmployee, typeCreateEmployee, setCreateEmployee } = soCreateEmployee();
	const { employee } = soEmployee();
	const { setOpenDelete } = soConfirmDelete();
	const [showMoreHour, setOpenMoreHour] = useState(false);
	const [semana, setSemana] = useState([
		'Lunes',
		'Martes',
		'Miércoles',
		'Jueves',
		'Viernes'
	]);

	const [data, setData] = useState({
		dni: '',
		nombre: '',
		apellidos: '',
		ccaa: '',
		sede: '',
		direccion: '',
		cp: '',
		provincia: '',
		localidad: '',
		telefono: '',
		vacacionesAMayores: '',
		email: '',
		hora1Inicio: '',
		hora1Fin: '',
		hora1Inicio2: '',
		hora1Fin2: '',

		hora2Inicio: '',
		hora2Fin: '',
		hora2Inicio2: '',
		hora2Fin2: '',

		hora3Inicio: '',
		hora3Fin: '',
		hora3Inicio2: '',
		hora3Fin2: '',

		hora4Inicio: '',
		hora4Fin: '',
		hora4Inicio2: '',
		hora4Fin2: '',

		hora5Inicio: '',
		hora5Fin: '',
		hora5Inicio2: '',
		hora5Fin2: '',

		horasMensuales: '-1',
		horasAnuales: '-1'
	});

	const [error, setError] = useState({
		dni: false,
		nombre: false,
		apellidos: false,
		ccaa: false,
		sede: false,
		direccion: false,
		cp: false,
		provincia: false,
		localidad: false,
		telefono: false,
		vacacionesAMayores: false,
		email: false,
		hora1Inicio: false,
		hora1Fin: false,
		hora1Inicio2: false,
		hora1Fin2: false,

		hora2Inicio: false,
		hora2Fin: false,
		hora2Inicio2: false,
		hora2Fin2: false,

		hora3Inicio: false,
		hora3Fin: false,
		hora3Inicio2: false,
		hora3Fin2: false,

		hora4Inicio: false,
		hora4Fin: false,
		hora4Inicio2: false,
		hora4Fin2: false,

		hora5Inicio: false,
		hora5Fin: false,
		hora5Inicio2: false,
		hora5Fin2: false,

		horasMensuales: false,
		horasAnuales: false,

		horario: false
	});

	function createEmployeeBBDD() {
		let error = false;
		const errorFounded = {
			dni: false,
			nombre: false,
			apellidos: false,
			ccaa: false,
			sede: false,
			direccion: false,
			cp: false,
			provincia: false,
			localidad: false,
			telefono: false,
			vacacionesAMayores: false,
			email: false,
			hora1Inicio: false,
			hora1Fin: false,
			hora1Inicio2: false,
			hora1Fin2: false,

			hora2Inicio: false,
			hora2Fin: false,
			hora2Inicio2: false,
			hora2Fin2: false,

			hora3Inicio: false,
			hora3Fin: false,
			hora3Inicio2: false,
			hora3Fin2: false,

			hora4Inicio: false,
			hora4Fin: false,
			hora4Inicio2: false,
			hora4Fin2: false,

			hora5Inicio: false,
			hora5Fin: false,
			hora5Inicio2: false,
			hora5Fin2: false,

			horasMensuales: false,
			horasAnuales: false,

			horario: false
		};

		if (data.dni == '') {
			errorFounded.dni = true;
			error = true;
		}
		if (data.nombre == '') {
			errorFounded.nombre = true;
			error = true;
		}
		if (data.apellidos == '') {
			errorFounded.apellidos = true;
			error = true;
		}
		if (data.ccaa == '') {
			errorFounded.ccaa = true;
			error = true;
		}
		if (data.sede == '') {
			errorFounded.sede = true;
			error = true;
		}
		if (data.direccion == '') {
			errorFounded.direccion = true;
			error = true;
		}
		if (data.cp == '') {
			errorFounded.cp = true;
			error = true;
		}
		if (data.provincia == '') {
			errorFounded.provincia = true;
			error = true;
		}
		if (data.localidad == '') {
			errorFounded.localidad = true;
			error = true;
		}
		if (data.telefono == '') {
			errorFounded.telefono = true;
			error = true;
		}
		if (data.email == '') {
			errorFounded.email = true;
			error = true;
		}
		if (data.horasMensuales == '') {
			errorFounded.horasMensuales = true;
			error = true;
		}
		if (data.horasAnuales == '') {
			errorFounded.horasAnuales = true;
			error = true;
		}
		if (
			data.hora1Inicio == '' ||
			data.hora1Fin == '' ||
			data.hora2Inicio == '' ||
			data.hora2Fin == '' ||
			data.hora3Inicio == '' ||
			data.hora3Fin == '' ||
			data.hora4Inicio == '' ||
			data.hora4Fin == '' ||
			data.hora5Inicio == '' ||
			data.hora5Fin == ''
		) {
			errorFounded.horario = true;
			error = true;
		}

		setError(errorFounded);

		if (!error) {
			fetch('https://back.ugt-sp.es/createEmployee', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			})
				.then((results) => {
					return results.json();
				})
				.then((res) => {
					if (res.status == 'ok') {
						setEmployeeStatus(!employeeStatus);
						setCreateEmployee(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	function editEmployee() {
		let error = false;
		const errorFounded = {
			dni: false,
			nombre: false,
			apellidos: false,
			ccaa: false,
			sede: false,
			direccion: false,
			cp: false,
			provincia: false,
			localidad: false,
			telefono: false,
			vacacionesAMayores: false,
			email: false,
			horasMensuales: false,
			horasAnuales: false,
			horario: false
		};

		if (data.dni == '') {
			errorFounded.dni = true;
			error = true;
		}
		if (data.nombre == '') {
			errorFounded.nombre = true;
			error = true;
		}
		if (data.apellidos == '') {
			errorFounded.apellidos = true;
			error = true;
		}
		if (data.ccaa == '') {
			errorFounded.ccaa = true;
			error = true;
		}
		if (data.sede == '') {
			errorFounded.sede = true;
			error = true;
		}
		if (data.direccion == '') {
			errorFounded.direccion = true;
			error = true;
		}
		if (data.cp == '') {
			errorFounded.cp = true;
			error = true;
		}
		if (data.provincia == '') {
			errorFounded.provincia = true;
			error = true;
		}
		if (data.localidad == '') {
			errorFounded.localidad = true;
			error = true;
		}
		if (data.telefono == '') {
			errorFounded.telefono = true;
			error = true;
		}
		if (data.email == '') {
			errorFounded.email = true;
			error = true;
		}
		if (data.horasMensuales == '') {
			errorFounded.horasMensuales = true;
			error = true;
		}
		if (data.horasAnuales == '') {
			errorFounded.horasAnuales = true;
			error = true;
		}
		if (
			data.hora1Inicio == '' ||
			data.hora1Fin == '' ||
			data.hora2Inicio == '' ||
			data.hora2Fin == '' ||
			data.hora3Inicio == '' ||
			data.hora3Fin == '' ||
			data.hora4Inicio == '' ||
			data.hora4Fin == '' ||
			data.hora5Inicio == '' ||
			data.hora5Fin == ''
		) {
			errorFounded.horario = true;
			error = true;
		}

		setError(errorFounded);

		if (!error) {
			fetch('https://back.ugt-sp.es/updateEmployee', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			})
				.then((results) => {
					return results.json();
				})
				.then((res) => {
					if (res.status == 'ok') {
						setEmployeeStatus(!employeeStatus);
						setCreateEmployee(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	function deleteEmployee() {
		fetch('https://back.ugt-sp.es/deleteEmployee', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ dni: data.dni })
		})
			.then((results) => {
				return results.json();
			})
			.then((res) => {
				if (res.status == 'ok') {
					setEmployeeStatus(!employeeStatus);
					setCreateEmployee(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	useEffect(() => {
		if (typeCreateEmployee == 'edit') {
			let horario = JSON.parse(employee.horario);
			setData({
				dni: employee.dni,
				nombre: employee.nombre,
				apellidos: employee.apellidos,
				ccaa: employee.ccaa,
				sede: employee.sede,
				direccion: employee.direccion,
				cp: employee.cp,
				provincia: employee.provincia,
				localidad: employee.localidad,
				telefono: employee.telefono,
				vacacionesAMayores: employee.vacacionesAMayores,
				email: employee.email,

				horasMensuales: employee.totalHorasMensualesSede,
				horasAnuales: employee.totalHorasAnualesSede,

				hora1Inicio: horario.entrada1_1,
				hora1Fin: horario.salida1_1,
				hora1Inicio2: horario.entrada1_2,
				hora1Fin2: horario.salida1_2,

				hora2Inicio: horario.entrada2_1,
				hora2Fin: horario.salida2_1,
				hora2Inicio2: horario.entrada2_2,
				hora2Fin2: horario.salida2_2,

				hora3Inicio: horario.entrada3_1,
				hora3Fin: horario.salida3_1,
				hora3Inicio2: horario.entrada3_2,
				hora3Fin2: horario.salida3_2,

				hora4Inicio: horario.entrada4_1,
				hora4Fin: horario.salida4_1,
				hora4Inicio2: horario.entrada4_2,
				hora4Fin2: horario.salida4_2,

				hora5Inicio: horario.entrada5_1,
				hora5Fin: horario.salida5_1,
				hora5Inicio2: horario.entrada5_2,
				hora5Fin2: horario.salida5_2
			});

			if (
				horario.entrada1_2 != 'none' ||
				horario.salida1_2 != 'none' ||
				horario.entrada2_2 != 'none' ||
				horario.salida2_2 != 'none' ||
				horario.entrada3_2 != 'none' ||
				horario.salida3_2 != 'none' ||
				horario.entrada4_2 != 'none' ||
				horario.salida4_2 != 'none' ||
				horario.entrada5_2 != 'none' ||
				horario.salida5_2 != 'none'
			) {
				setOpenMoreHour(true);
			}
		} else {
			setData({
				dni: '',
				nombre: '',
				apellidos: '',
				ccaa: '',
				sede: '',
				direccion: '',
				cp: '',
				provincia: '',
				localidad: '',
				telefono: '',
				vacacionesAMayores: '',
				email: '',
				hora1Inicio: '',
				hora1Fin: '',

				horasMensuales: '-1',
				horasAnuales: '-1',

				hora1Inicio2: '00:00',
				hora1Fin2: '00:00',

				hora2Inicio: '',
				hora2Fin: '',
				hora2Inicio2: '00:00',
				hora2Fin2: '00:00',

				hora3Inicio: '',
				hora3Fin: '',
				hora3Inicio2: '00:00',
				hora3Fin2: '00:00',

				hora4Inicio: '',
				hora4Fin: '',
				hora4Inicio2: '00:00',
				hora4Fin2: '00:00',

				hora5Inicio: '',
				hora5Fin: '',
				hora5Inicio2: '00:00',
				hora5Fin2: '00:00'
			});
			setError({
				dni: false,
				nombre: false,
				apellidos: false,
				ccaa: false,
				sede: false,
				direccion: false,
				cp: false,
				provincia: false,
				localidad: false,
				telefono: false,
				vacacionesAMayores: false,
				email: false,

				horasMensuales: false,
				horasAnuales: false,

				hora1Inicio: false,
				hora1Fin: false,
				hora1Inicio2: false,
				hora1Fin2: false,

				hora2Inicio: false,
				hora2Fin: false,
				hora2Inicio2: false,
				hora2Fin2: false,

				hora3Inicio: false,
				hora3Fin: false,
				hora3Inicio2: false,
				hora3Fin2: false,

				hora4Inicio: false,
				hora4Fin: false,
				hora4Inicio2: false,
				hora4Fin2: false,

				hora5Inicio: false,
				hora5Fin: false,
				hora5Inicio2: false,
				hora5Fin2: false,

				horario: false
			});
		}
	}, [createEmployee]);

	return (
		<>
			<Modal
				open={createEmployee}
				onClose={() => setCreateEmployee(false)}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					{typeCreateEmployee == 'edit' ? (
						<Typography id='modal-modal-title' variant='h6' component='h2'>
							Editar empleado{' '}
						</Typography>
					) : (
						<Typography id='modal-modal-title' variant='h6' component='h2'>
							Crear empleado
						</Typography>
					)}

					<Typography id='modal-modal-description' sx={{ mt: 2 }}>
						Introduce a continuación los datos del empleado:
					</Typography>
					<div className='form-group mt-3'>
						<label htmlFor='employee-name'>DNI</label>
						<input
							type='text'
							className='form-control'
							id='employee-dni'
							placeholder='Introduce el DNI'
							defaultValue={data.dni}
							disabled={typeCreateEmployee == 'edit'}
							onChange={(e) => setData({ ...data, dni: e.target.value })}
						/>
						<div
							className={
								error.dni
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir un DNI.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='employee-name'>Nombre</label>
						<input
							type='text'
							className='form-control'
							id='employee-name'
							placeholder='Introduce el nombre'
							defaultValue={data.nombre}
							onChange={(e) => setData({ ...data, nombre: e.target.value })}
						/>
						<div
							className={
								error.nombre
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir un nombre.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='employee-surname'>Apellidos</label>
						<input
							type='text'
							className='form-control'
							id='employee-surname'
							placeholder='Introduce los apellidos'
							defaultValue={data.apellidos}
							onChange={(e) =>
								setData({ ...data, apellidos: e.target.value })
							}
						/>
						<div
							className={
								error.apellidos
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir un apellido.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='employee-comunidad'>Comunidad Autónoma</label>
						<SelectCCAA
							data={data}
							defaultValue={data.ccaa}
							changeFunction={setData}
							dataType='ccaa'
						/>
						{/* <select className="form-select" id="employee-comunidad" value={data.ccaa} onChange={e => setData({...data, ccaa: e.target.value})} >
                            <option selected disabled>Introduce la Comunidad Autónoma</option>
                            <option value="Madrid">Madrid</option>
                            <option value="Galicia">Galicia</option>
                            <option value="Andalucía">Andalucía</option>
                            <option value="Otras">Otras</option>
                        </select> */}
						<div
							className={
								error.ccaa
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir una comunidad.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='employee-sede'>Sede</label>
						<SelectSede
							data={data}
							defaultValue={data.sede}
							changeFunction={setData}
							dataType='sede'
						/>
						{/* <select className="form-select" id="employee-sede" value={data.sede} onChange={e => setData({...data, sede: e.target.value})} >
                            <option selected disabled>Introduce la sede</option>
                            <option value="1">Sede 1</option>
                            <option value="2">Sede 2</option>
                            <option value="3">Sede 3</option>
                            <option value="3">Sede 4</option>
                        </select> */}
						<div
							className={
								error.sede
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir una sede.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='employee-address'>Dirección</label>
						<input
							type='text'
							className='form-control'
							id='employee-address'
							placeholder='Introduce la dirección'
							defaultValue={data.direccion}
							onChange={(e) =>
								setData({ ...data, direccion: e.target.value })
							}
						/>
						<div
							className={
								error.direccion
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir una dirección.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='employee-cp'>Código Postal</label>
						<input
							type='number'
							min='0'
							className='form-control'
							id='employee-cp'
							placeholder='Introduce el código postal'
							defaultValue={data.cp}
							onChange={(e) => setData({ ...data, cp: e.target.value })}
						/>
						<div
							className={
								error.cp ? 'invalid-feedback d-block' : 'invalid-feedback'
							}
						>
							Debes introducir un código postal.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='employee-provincia'>Provincia</label>
						<input
							type='text'
							className='form-control'
							id='employee-provincia'
							placeholder='Introduce la provincia'
							defaultValue={data.provincia}
							onChange={(e) =>
								setData({ ...data, provincia: e.target.value })
							}
						/>
						<div
							className={
								error.provincia
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir una provincia.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='employee-localidad'>Localidad</label>
						<input
							type='text'
							className='form-control'
							id='employee-localidad'
							placeholder='Introduce la población'
							defaultValue={data.localidad}
							onChange={(e) =>
								setData({ ...data, localidad: e.target.value })
							}
						/>
						<div
							className={
								error.localidad
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir una localidad.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='employee-email'>Email</label>
						<input
							type='email'
							className='form-control'
							id='employee-email'
							placeholder='Introduce el email'
							defaultValue={data.email}
							onChange={(e) => setData({ ...data, email: e.target.value })}
						/>
						<div
							className={
								error.email
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir un email.
						</div>
					</div>
					<div className='form-group mt-3'>
						<label htmlFor='employee-telefono'>Teléfono</label>
						<input
							type='number'
							min='0'
							className='form-control'
							id='employee-telefono'
							placeholder='Introduce el teléfono'
							defaultValue={data.telefono}
							onChange={(e) =>
								setData({ ...data, telefono: e.target.value })
							}
						/>
						<div
							className={
								error.telefono
									? 'invalid-feedback d-block'
									: 'invalid-feedback'
							}
						>
							Debes introducir un teléfono.
						</div>
					</div>
					{typeCreateEmployee == 'edit' ? (
						<div className='form-group mt-3'>
							<label htmlFor='employee-telefono'>
								Días de vacaciones a mayores de los de la sede
							</label>
							<input
								type='number'
								min='0'
								className='form-control'
								id='employee-telefono'
								placeholder='Introduce los días'
								defaultValue={data.vacacionesAMayores}
								onChange={(e) =>
									setData({
										...data,
										vacacionesAMayores: e.target.value
									})
								}
							/>
							<div
								className={
									error.vacacionesAMayores
										? 'invalid-feedback d-block'
										: 'invalid-feedback'
								}
							>
								Debes introducir los dias.
							</div>
						</div>
					) : null}

					<div className='form-group mt-3'>
						<hr />
						<p>
							Solo modificar si el empleado tiene diferentes horas que la
							sede
						</p>
						<label htmlFor='employee-horasMensuales' className='mt-3'>
							Horas mensuales
						</label>
						<input
							type='number'
							min='0'
							className='form-control'
							id='employee-horasMensuales'
							placeholder='Introduce las horas mensuales'
							defaultValue={data.horasMensuales}
							onChange={(e) =>
								setData({ ...data, horasMensuales: e.target.value })
							}
						/>

						<label htmlFor='employee-horasAnuales' className='mt-3'>
							Horas Anuales
						</label>
						<input
							type='number'
							min='0'
							className='form-control'
							id='employee-horasAnuales'
							placeholder='Introduce las horas anuales'
							defaultValue={data.horasAnuales}
							onChange={(e) =>
								setData({ ...data, horasAnuales: e.target.value })
							}
						/>
					</div>

					<div className='form-group mt-4'>
						<hr />

						<div className='row'>
							<div className='col-6'>
								<h3 className='text-center'>Horarios</h3>
								<div
									className={
										error.horario
											? 'invalid-feedback d-block text-center'
											: 'invalid-feedback'
									}
								>
									Debes introducir los horarios.
								</div>
							</div>

							<div className='col-6'>
								<button
									onClick={() => setOpenMoreHour(!showMoreHour)}
									aria-controls='example-collapse-text'
									aria-expanded={showMoreHour}
									className='btn btn-outline-dark btn-sm w-100'
								>
									Añadir segundo turno
								</button>
							</div>
						</div>

						<hr />
					</div>

					{semana.map((dia, index) => {
						return (
							<div key={dia} className='mt-4'>
								<h4 className='text-center'>Horario {dia}</h4>

								<div className='form-group mt-3'>
									<label>Hora Entrada</label>
									<input
										type='time'
										className='form-control'
										defaultValue={data[`hora${index + 1}Inicio`]}
										onChange={(e) =>
											setData({
												...data,
												[`hora${index + 1}Inicio`]: e.target.value
											})
										}
									/>
									<div
										className={
											error[`hora${index + 1}Inicio`]
												? 'invalid-feedback d-block'
												: 'invalid-feedback'
										}
									>
										Debes introducir una hora inicio.
									</div>
								</div>
								<div className='form-group mt-3'>
									<label>Hora Salida</label>
									<input
										type='time'
										className='form-control'
										defaultValue={data[`hora${index + 1}Fin`]}
										onChange={(e) =>
											setData({
												...data,
												[`hora${index + 1}Fin`]: e.target.value
											})
										}
									/>
									<div
										className={
											error[`hora${index + 1}Fin`]
												? 'invalid-feedback d-block'
												: 'invalid-feedback'
										}
									>
										Debes introducir una hora de fin.
									</div>
								</div>

								<Collapse in={showMoreHour}>
									<div id='example-collapse-text'>
										<p
											className='mt-3'
											style={{ fontSize: 18, fontWeight: 'bold' }}
										>
											Segundo Turno:
										</p>
										<div className='form-group mt-3'>
											<label>Hora Entrada</label>
											<input
												type='time'
												className='form-control'
												value={data[`hora${index + 1}Inicio2`]}
												onChange={(e) =>
													setData({
														...data,
														[`hora${index + 1}Inicio2`]:
															e.target.value
													})
												}
											/>
										</div>
										<div className='form-group mt-3'>
											<label>Hora Salida</label>
											<input
												type='time'
												className='form-control'
												value={data[`hora${index + 1}Fin2`]}
												onChange={(e) =>
													setData({
														...data,
														[`hora${index + 1}Fin2`]:
															e.target.value
													})
												}
											/>
										</div>
									</div>
								</Collapse>
							</div>
						);
					})}
					{/* <div className="form-group mt-3">
                        <label>Hora Entrada</label>
                        <input type="time" className="form-control" defaultValue={data.hora1Inicio} id="employee-entry-time-1" onChange={e => setData({...data, hora1Inicio: e.target.value})} />
                        <div className={error.hora1Inicio ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir una hora inicio.
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label>Hora Salida</label>
                        <input type="time" className="form-control" defaultValue={data.hora1Fin} id="employee-exit-time-1" onChange={e => setData({...data, hora1Fin: e.target.value})} />
                        <div className={error.hora1Fin ? "invalid-feedback d-block" : "invalid-feedback"}>
                            Debes introducir una hora de fin.
                        </div>
                    </div>
                    <button
                        onClick={() => setOpenMoreHour(!showMoreHour)}
                        aria-controls="example-collapse-text"
                        aria-expanded={showMoreHour}
                        className="btn btn-outline-dark btn-sm w-100 mt-3"
                    >
                        Añadir segundo turno
                    </button>
                    <Collapse in={showMoreHour}>
                        <div id="example-collapse-text">
                            <div className="form-group mt-3">
                                <label>Hora Entrada</label>
                                <input type="time" className="form-control" defaultValue={data.hora2Inicio} id="employee-entry-time-1" onChange={e => setData({...data, hora2Inicio: e.target.value})} />
                            </div>
                            <div className="form-group mt-3">
                                <label>Hora Salida</label>
                                <input type="time" className="form-control" defaultValue={data.hora2Fin} id="employee-exit-time-1" onChange={e => setData({...data, hora2Fin: e.target.value})} />
                            </div>
                        </div>
                    </Collapse> */}

					<div
						className='mt-2'
						style={{
							fontSize: '15px',
							textDecoration: 'underline',
							cursor: 'pointer',
							color: 'var(--color-primary)'
						}}
						onClick={() => {
							fetch('https://back.ugt-sp.es/resetEmployeePassword', {
								method: 'POST',
								headers: {
									'Content-Type': 'application/json'
								},
								body: JSON.stringify({ dni: data.dni })
							})
								.then((results) => {
									return results.json();
								})
								.then(async (res) => {
									if (res.updated) {
										NotificationManager.success(
											'Contraseña reseteada correctamente',
											'Contraseña reseteada',
											3000
										);
										setEmployeeStatus(!employeeStatus);
										setCreateEmployee(false);
									} else {
										await NotificationManager.error(
											'Revisa que el correo del empleado sea correcto',
											'Error al enviar el correo al usuario',
											10000
										);
										setCreateEmployee(false);
										await navigator.clipboard.writeText(res.password);

										alert(
											'Se ha creado una contraseña temporal, pero no se ha podido enviar el correo al usuario.\n\nContraseña: ' +
												res.password
										);

										NotificationManager.success(
											'Contraseña copiada al portapapeles',
											'Contraseña copiada',
											3000
										);
									}
								})
								.catch((error) => {
									NotificationManager.error(
										'Error al resetear la contraseña',
										'Error',
										3000
									);
									console.log(error);
								});
						}}
					>
						Restablecer Contraseña
					</div>
					<div className='d-flex justify-content-between mt-4'>
						<button
							type='button'
							className={
								typeCreateEmployee == 'edit'
									? 'btn btn-dark w-33'
									: 'btn btn-dark w-50 me-1'
							}
							onClick={() => setCreateEmployee(false)}
						>
							<CloseIcon /> Cancelar
						</button>
						{typeCreateEmployee == 'edit' ? (
							<>
								<button
									type='button'
									className='btn btn-success w-33 ms-1'
									onClick={() => editEmployee()}
								>
									<SaveIcon /> Guardar
								</button>

								{employee.activo == 1 ? (
									<button
										type='button'
										className='btn btn-danger w-33 ms-1'
										onClick={() => deleteEmployee()}
									>
										<Delete /> Deshabilitar
									</button>
								) : (
									<button
										type='button'
										className='btn btn-success w-33 ms-1'
										onClick={() => {
											fetch(
												'https://back.ugt-sp.es/activeEmployee',
												{
													method: 'POST',
													headers: {
														'Content-Type': 'application/json'
													},
													body: JSON.stringify({
														dni: data.dni
													})
												}
											)
												.then((results) => {
													return results.json();
												})
												.then((res) => {
													console.log(res);

													if (res.status == 'ok') {
														setEmployeeStatus(
															!employeeStatus
														);
														setCreateEmployee(false);
													}
												})
												.catch((error) => {
													console.log(error);
												});
										}}
									>
										<Check /> Habilitar
									</button>
								)}
							</>
						) : (
							<button
								type='button'
								className='btn btn-primary w-50 ms-1'
								onClick={() => createEmployeeBBDD()}
							>
								<CheckIcon /> Crear
							</button>
						)}
					</div>

					<ConfirmDelete deleteFunction={deleteEmployee} />
				</Box>
			</Modal>
		</>
	);
}
