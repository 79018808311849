import React from 'react';
import { Nav, Navbar, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useRol } from '../../common/useRol';

import useToken from '../../common/useToken';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/Header.css';

export default function Header() {
    const {rol} = useRol();
    const { token, setToken } = useToken();

    return (
        <>
            <Navbar collapseOnSelect expand="sm" className="bg-corp navbar-fixed-top" variant="dark">
                <Navbar.Toggle aria-controls="navbarScroll" data-bs-toggle="collapse" data-bs-target="#navbarScroll" />
                <img alt="logo" style={{width:"200px"}} className="ms-2" src="/img/logo1.png"></img>
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="nav_menu_header" style={{alignItems:"center", verticalAlign:"middle"}}>
                        <div class="d-flex">
                            <div className="menu-nav">
                                {/* <NavLink className="logo" eventKey="1" as={Link} to="/">Inicio</NavLink> */}
                                <NavLink eventKey="/fichajes" as={Link} to="/fichajes/lista" onClick={() => window.localStorage.setItem("url", "/fichajes/lista")}>Fichajes</NavLink>
                                <NavLink eventKey="/empleados" as={Link} to="/empleados/lista" onClick={() => window.localStorage.setItem("url", "/empleados/lista")}>Empleados</NavLink>
                                {rol === "Administrador" || rol === "Responsable_ccaa" ? <NavLink eventKey="/usuarios" as={Link} to="/usuarios" onClick={() => window.localStorage.setItem("url", "/usuarios")}>Usuarios</NavLink> : null }
                                <NavLink eventKey="/anuncios" as={Link} to="/anuncios" onClick={() => window.localStorage.setItem("url", "/anuncios")}>Anuncios</NavLink>
                                <NavLink eventKey="/calendario" as={Link} to="/calendario" onClick={() => window.localStorage.setItem("url", "/calendario")}>Calendario</NavLink>
                                <NavLink eventKey="/informes" as={Link} to="/informes" onClick={() => window.localStorage.setItem("url", "/informes")}>Informes</NavLink>
                            </div>
                        </div>
                        
                        <NavLink className="btn btn-logout me-2 d-flex align-items-center justify-content-center" eventKey="/logout" as={Link} to="/" onClick={() => {
                            setToken(null)
                            window.localStorage.clear()
                        }}><ExitToAppIcon className="me-2" fontSize="small" onClick={() => window.localStorage.setItem("url", "")} /> Cerrar Sesión</NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}
